<template>
  <div class="left-menu">
    <BaseContentBox :width="240" :title="title" type="menu">
      <div class="menu-container">
        <a-menu
          style="width: 260px"
          mode="inline"
          theme="dark"
          v-model="selectKey"
          :open-keys="openKeys"
        >
          <slot v-for="item in menus">
            <a-sub-menu
              :key="item.name"
              v-if="
                item.children && item.children.length && item.meta.isShowChild
              "
            >
              <span slot="title">
                <a-icon :type="item.meta.icon" />
                <span>{{ item.meta.title }}</span>
              </span>
              <slot v-for="list in item.children">
                <a-menu-item :key="list.name" v-if="list.meta.isNav">
                  <router-link :to="list.path">{{
                    list.meta.title
                  }}</router-link>
                </a-menu-item>
              </slot>
            </a-sub-menu>
            <a-menu-item :key="item.name" v-else>
              <router-link :to="item.path">
                <a-icon :type="item.meta.icon" /> {{ item.meta.title }}
              </router-link>
            </a-menu-item>
          </slot>
        </a-menu>
      </div>
    </BaseContentBox>
  </div>
</template>


<script>
import BaseContentBox from "@/components/BaseContentBox";
export default {
  components: {
    BaseContentBox,
  },
  computed: {
    menus() {
      let _p = this.$route.path.split("/")[1];
      return this.$router.options.routes.filter((item) => item.name == _p)[0]
        .children;
    },
  },
  data() {
    return {
      selectKey: [],
      openKeys: [],
      title: "",
    };
  },
  created() {
    this.openKeys = this.menus
      .filter((item) => item.children && item.children.length > 0)
      .map((item) => item.name);
    this.getSelectKey(this.$route);
    let _p = this.$route.path.split("/")[1];
    if (_p == "pcms") {
      this.title = "人员管理系统";
    } else if (_p == "pcms") {
      this.title = "";
    } else if (_p == "mdms") {
      this.title = "设备管理系统";
    } else if (_p == "mms") {
      this.title = "材料管理管理系统";
    } else if (_p == "spms") {
      this.title = "广播管理系统";
    } else if (_p == "qsms") {
      this.title = "质量监督管理系统";
    } else if (_p == "ssms") {
      this.title = "安全监督管理系统";
    } else if (_p == "pims") {
      this.title = "项目信息管理系统";
    } else if (_p == "vedioMonitor") {
      this.title = "视频监控";
    } else if (_p == "collision-alarm") {
      this.title = "预警中心";
    } else {
      this.title = "";
    }
  },
  methods: {
    getSelectKey(router) {
      this.menus.forEach((item) => {
        if (item.children && item.children.length && item.meta.isShowChild) {
          item.children.forEach((v) => {
            if (router.name.includes(v.name)) {
              this.selectKey = [v.name];
            }
          });
        } else {
          if (router.name.includes(item.name)) {
            this.selectKey = [item.name];
          }
        }
      });
    },
  },
  watch: {
    $route: function (router) {
      this.getSelectKey(router);
    },
  },
};
</script>