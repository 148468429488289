/*
    content:质量监督管理系统接口列表
    time:2020-10-12

*/ 


import http from '../http'

//质量巡检
const inspectionApi = {
    queryQualityRecordList: p => http.get('quality-service/qualityRecord/page', p), //巡查记录-列表
    queryQualityRecordDetails: id => http.get(`quality-service/qualityRecord/${id}`), //巡查记录-详情
    insertQualityRecord: p => http.postJson('quality-service/qualityRecord', p), //巡查记录-新增
    updateQualityRecord: p => http.put('quality-service/qualityRecord', p), //巡查记录-编辑
    deleteQualityRecord: id => http.delete(`quality-service/qualityRecord/${id}`), //巡查记录-删除
    qualityRecordRectify: p => http.postJson('quality-service/qualityRecordRectify', p), //巡查记录-整改
    qualityRecordRecheck: p => http.postJson('quality-service/qualityRecordRecheck', p), //巡查记录-复核
    qualityRecordRectifyForward: p => http.postJson('quality-service/qualityRecordRectifyForward', p), //巡查记录-移交
}

//基础配置
const configApi = {
    queryQualityLevelList: p => http.get('quality-service/qualityLevel/page', p), //问题等级-列表
    insertQualityLevel: p => http.postJson('quality-service/qualityLevel', p), //问题等级-新增
    updateQualityLevel: p => http.put('quality-service/qualityLevel', p), //问题等级-编辑
    deleteQualityLevel: id => http.delete(`quality-service/qualityLevel/${id}`), //问题等级-删除
    
}


export default Object.assign({},inspectionApi,configApi)