import Vue from 'vue'
import App from './App.vue'
import VueRouter from './router'
import VueStore from './store'

import Antd from 'ant-design-vue'
import ECharts from 'vue-echarts'
//样式
import 'ant-design-vue/dist/antd.css'
import '@/assets/styles/antd-custom.less'
import '@/assets/styles/antd-custom-sliderBar.scss'
import '@/assets/styles/basic.scss'
import '@/assets/styles/common.scss'
import '@/assets/styles/icon-font.scss'
import '@/assets/styles/icon-font.scss'


import echarts from 'echarts'
import 'echarts-gl'
Vue.prototype.$echarts = echarts

// eCharts按需加载
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/tooltip'
// import 'echarts-gl'
// import 'echarts-gl/lib/chart/surface'
// import 'echarts-gl/lib/component/grid3D'

Vue.component('v-chart', ECharts)
Vue.use(Antd)
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '@/assets/styles/swiper.css'
Vue.use(VueAwesomeSwiper)
import 'babel-polyfill'

//全局接口配置
// import './until/permission'
import API from './api/index'
Vue.prototype.$api = API

Vue.config.productionTip = false

let instance = null
let router = null

/**
 * 渲染函数
 * 两种情况：主应用生命周期钩子中运行 / 微应用单独启动时运行
 */
function render(props) {
    // 主应用共享的store实例
    const store = props && props.store ? Vue.observable(props.store) : VueStore

    // 在 render 中创建 VueRouter，可以保证在卸载微应用时，移除 location 事件监听，防止事件污染
    router = VueRouter

    // 挂载应用
    instance = new Vue({
        router: router,
        store: store,
        render: (h) => h(App)
    }).$mount('#micro-app')

    window.vueInstance = instance
    // document.title = '智慧营区管理云平台'
    document.title = '会展西路过江隧道工程营区信息化管理云平台'

}

// 独立运行时，直接挂载应用
if (!window.__POWERED_BY_QIANKUN__) {
    render()
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
    console.log('WorkSiteApp 引导完成')
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props) {
    console.log('WorkSiteApp 已挂载', props)
    render(props)
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
    console.log('WorkSiteApp 已卸载')
    instance.$destroy()
    instance = null
    router = null
}
