/*
    content:安全监督管理系统接口列表
    time:2020-10-12

*/ 


import http from '../http'
//安全巡检
const inspectionApi = {
    querySafeRecordList: p => http.get('safe-service/safeRecord/page', p), //巡查记录-列表
    safeRecordRecheck: p => http.postJson('safe-service/safeRecordRecheck', p), //巡查记录-复核
    safeRecordRectify: p => http.postJson('safe-service/safeRecordRectify', p), //巡查记录-整改
    safeRecordRectifyForward: p => http.postJson('safe-service/safeRecordRectifyForward', p), //巡查记录-移交
    insertSafeRecord: p => http.postJson('safe-service/safeRecord', p), //巡查记录-新增
    updateSafeRecord: p => http.put('safe-service/safeRecord', p), //巡查记录-编辑
    deleteSafeRecord: p => http.delete(`safe-service/safeRecord/${p}`), //巡查记录-删除
    querySafeRecordDetails: p => http.get(`safe-service/safeRecord/${p}`), //巡查记录-详情
}

//安全教育
const educationApi = {
    querySafeEducationList: p => http.get('safe-service/safeEducation/page', p), //安全教育-列表
    querySafeEducationDetails: p => http.get(`safe-service/safeEducation/${p}`), //安全教育-详情
    querySafeEducationStatistics: p => http.get(`safe-service/safeEducation/statistics`,p), //安全教育-详情
    insertSafeEducation: p => http.postJson(`safe-service/safeEducation`,p), //安全教育-添加
    updateSafeEducation: p => http.put(`safe-service/safeEducation`,p), //安全教育-编辑
    deleteSafeEducation: p => http.delete(`safe-service/safeEducation/${p}`), //安全教育-删除
}

//安全作业
const taskApi = {
    querySafeJobList: p => http.get('safe-service/safeJob/page', p), //安全作业-列表
    querySafeOccupationUserList: p => http.get('safe-service/safeOccupation/userList', p), //监管职务-人员列表
    querySafeJobDetails: p => http.get(`safe-service/safeJob/${p}`), //安全作业-详情
    insertSafeJob: p => http.postJson('safe-service/safeJob', p), //安全作业-新增
    safeJobSupervise: p => http.postJson('safe-service/safeJobSupervise', p), //安全作业-监管/移交
    updateSafeJob: p => http.put('safe-service/safeJob', p), //安全作业-修改
    deleteSafeJob: p => http.delete(`safe-service/safeJob/${p}`), //安全作业-删除
}

//基本配置
const configApi = {
    //问题等级
    querySafeLevelList: p => http.get('safe-service/safeLevel/page', p), //问题等级-列表
    insertSafeLevel: p => http.postJson('safe-service/safeLevel', p), //问题等级-新增
    updateSafeLevel: p => http.put('safe-service/safeLevel', p), //问题等级-编辑
    deleteSafeLevel: p => http.delete(`safe-service/safeLevel/${p}`), //问题等级-删除

    //监管职务
    querySafeOccupationList: p => http.get('safe-service/safeOccupation/page', p), //监管职务-列表
    insertSafeOccupation: p => http.postJson('safe-service/safeOccupation', p), //监管职务-新增
    updateSafeOccupation: p => http.put('safe-service/safeOccupation', p), //监管职务-编辑
    deleteSafeOccupation: p => http.delete(`safe-service/safeOccupation/${p}`), //监管职务-删除

    //作业类型
    querySafeJobTypeList: p => http.get('safe-service/safeJobType/page', p), //作业类型-列表
    insertSafeJobType: p => http.postJson('safe-service/safeJobType', p), //作业类型-新增
    updateSafeJobType: p => http.put('safe-service/safeJobType', p), //作业类型-编辑
    deleteSafeJobType: p => http.delete(`safe-service/safeJobType/${p}`), //作业类型-删除

    //安全教育-培训类型
    querySafeEducationTypeList: p => http.get('safe-service/safeEducationType/page', p), //安全教育-培训类型-列表
    insertSafeEducationType: p => http.postJson('safe-service/safeEducationType', p), //安全教育-培训类型-新增
    updateSafeEducationType: p => http.put('safe-service/safeEducationType', p), //安全教育-培训类型-编辑
    deleteSafeEducationType: p => http.delete(`safe-service/safeEducationType/${p}`), //安全教育-培训类型-删除

}


export default Object.assign({},inspectionApi,educationApi,taskApi,configApi)