// api接口地址

import MDMS_API from './module/mdms' //机械设备管理系统
import MMS_API from './module/mms' //材料管理系统
import PCMS_API from './module/pcms' //人员车辆管理系统
import PIMS_API from './module/pims' //项目信息管理系统

import QSMS_API from './module/qsms' //质量监督管理系统
import SPMS_API from './module/spms' //法规宣导管理系统
import SSMS_API from './module/ssms' //安全监督管理系统

import BIGDATA_API from './module/bigdata' //安全监督管理系统
import screen_API from './module/screen' //出入口大屏

import COMMON_API from './module/common' //全局公用接口
import YUJING_API from './module/yujing' //全局公用接口

export default Object.assign({}, YUJING_API,MDMS_API, MMS_API, PCMS_API, PIMS_API, QSMS_API, SPMS_API, SSMS_API, BIGDATA_API, COMMON_API,screen_API)
