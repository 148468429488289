import http from '../http'
// 大屏
const passagewaygApi = {
    //    出入口列表  /device/doorway/doorwayList
    doorwayList: p => http.get('/device/doorway/list', p),
    //人员进出记录
    getPersonnelRecords: p => http.get('/personnel/personnelFaceCollectionLog/getPersonnelRecords', p),
    // 车辆进出记录
    getCarRecords: p => http.get('/personnel/carInOutLog/getCarRecords', p),
    // 出入口预警
    getInOutAlarmRecordList: p => http.get('/alarm1/alarmRecord/getInOutAlarmRecordList', p),
    // 预警趋势
    getInOutAlarmTrend: p => http.get('/alarm1/alarmRecord/getInOutAlarmTrend', p),
    // 人车统计
    getStatistical: p => http.get('/personnel/attendanceEntranceGuard/getStatistical', p)

}

// 塔吊
const towerApi = {
    //    塔吊列表 /device/device/list
    deviceList: p => http.get('/device/device/list', p),
    //  塔吊实时数据/device/device/getTowerCraneRealTimeData
    getTowerCraneRealTimeData: p => http.get('/device/device/getTowerCraneRealTimeData', p),
    // 塔吊预警
    getInOutAlarmRecordList: p => http.get('/alarm1/alarmRecord/getInOutAlarmRecordList', p),
    // 预警趋势
    getInOutAlarmTrend: p => http.get('/alarm1/alarmRecord/getInOutAlarmTrend', p),
}

// 物联大屏
const webThingApi = {
    // 物联大屏-实时预警数
    getWarningNumber: p => http.get('/alarm1/alarmRecord/getWarningNumber', p),
    // 物联大屏-设备状态统计
    getStatusStatistical: p => http.get('/device/device/getStatusStatistical', p),
    // 设备历史趋势
    getDevStatusTrend: p => http.get('/device/device/getDevStatusTrend', p),
    // 物联大屏-设备类型统计
    getTypeStatistical: p => http.get('/device/device/getTypeStatistical', p),
    // 物联大屏-设备异常记录
    getAbnormalRecords: p => http.get('/device/device/getAbnormalRecords', p),
    // 预警类型统计
    getWarningType: p => http.get('/alarm1/alarmRecord/getWarningType', p),
    // 预警等级 
    getWarningLevel: p => http.get('/alarm1/alarmRecord/getWarningLevel', p),
    // 物联大屏-维保统计
    getMaintenanceStatistical: p => http.get('/device/deviceMaintenance/getMaintenanceStatistical', p),
}
// 公用大屏
const publicMontiorApi = {
    // 重点设备监控
    getGroupStatistical: p => http.get('/device/device/getGroupStatistical', p),
    // 安全列表 
    safeRecord: p => http.get('/safe-service/safeRecord/page', p),
    // 质安巡检-安全数量汇总
    safeStatistics: p => http.get('/safe-service/safeRecord/statistics', p),
    // 质安巡检-质量-列表
    qualityRecord: p => http.get('/quality-service/qualityRecord/page', p),
    // 质安巡检-安全数量汇总
    qualityStatistics: p => http.get('/quality-service/qualityRecord/statistics', p),
    // 安全作业 
    safeJob: p => http.get('/safe-service/safeJob/dashboard/statistics', p),
    // 安全培训
    safeEducation: p => http.get('/safe-service/safeEducation/dashboard/statistics', p),
    // 劳资统计-人员出勤统计
    getAttendanceStatistics: p => http.get('/personnel/personnel/getAttendanceStatistics', p),
    // 预警监测数量统计
    getWarningStatistics: p => http.get('/alarm1/alarmRecord/getWarningStatistics', p),
    // 环境监测-天气
    deviceWeatherData: p => http.get('/device-service/deviceWeatherData/dashboard/statistics', p),
    // 环境监测-温湿度等
    deviceEnviCurData: p => http.get('/device-service/deviceEnviCurData/dashboard/statistics', p),
    // 能耗监测
    getElectricityStatistical: p => http.get('/device/device/getElectricityStatistical', p),
    // 出入口监测人员进出记录
    personnelGetInOutLog: p => http.get('/personnel/personnel/getInOutLog', p),
    // 出入口监测人员进出记录
    personnelGetInOutLog2: p => http.get('/personnel/personnelFaceCollectionLog/getPersonnelAccess', p),
    // 劳资统计-出入口监测车辆进出记录
    personnelGetCarLog: p => http.get('/personnel/carInOutLog/getCarLog', p),
    // 工程进度  /project/milestoneConfig/getMilestoneConfig
    getMilestoneConfig: p => http.get('/project/milestoneConfig/getMilestoneConfig', p),
    //当前投放配置 /publicize/contentSet/getSettingData
    getSettingData: p => http.get('/publicize/contentSet/getSettingData', p),
    //监控摄像头列表
    queryCameraList: p => http.get('/device/device/cameraList', p),
    queryCameraLists: p => http.get('/v1/customization/cameraList', p),//监控摄像头列表 
    queryCameraAccountInfo: p => http.get('/v1/ezviz/account/info', p),//监控rq视频token
    queryCameraOauthtToken: p => http.post('/oauth/token', p),//第三方token
    //工资 /personnel/companyPaySalaryTotal/statistics
    companyPaySalaryTotal: p => http.get('/personnel/companyPaySalaryTotal/statistics', p),
    //登录监控
    loginCamera: p => http.get(`/device/monitoring/hik/cloud/oauth/token`, p),




    // 调整大屏的接口
    getTodayIntoStatistics: p => http.get(`/personnel-service/personnel/getTodayIntoStatistics`, p),//今日在场
    getFutureThreedays: p => http.get(`/device-service/deviceWeatherData/getFutureThreedays`, p),//环境监测-未来三日气象
    getDashboardStatistics: p => http.get(`/device-service/deviceEnviCurData/dashboard/statistics`, p),//环境监测-环境监测
    getDeviceElectricityStatistical: p => http.get(`/device-service/device/getElectricityStatistical`, p),//能耗监测
    getTodayElectricityStatistical: p => http.get(`/device-service/device/getTodayElectricityStatistical`, p),//今日能耗
    getWarnMonitorStatistics: p => http.get(`/warning/event/getWarnMonitorStatistics`, p),//预警监测-统计
    getWarnMonitorList: p => http.get(`/warning/event/getWarnMonitorList`, p),//预警监测-列表



    getElectricityStatisticals: p => http.get(`/device-service/device/getElectricityStatisticals`, p),//用电监测
    getWaterStatisticals: p => http.get(`/device-service/device/getWaterStatisticals`, p),//用水监测
    getDeviceGroupStatistical: p => http.get(`/device-service/device/getGroupStatistical`, p),//设备监测
    getPresenceStatistics: p => http.get(`/personnel-service/personnelFaceCollectionLog/getPresenceStatistics`, p),//设备监测
    getIntoTrends: p => http.get(`/personnel-service/personnelFaceCollectionLog/getIntoTrends`, p),//进场趋势
    getInOutAccount: p => http.get(`/personnel-service/personnelFaceCollectionLog/getInOutAccount`, p),//出入台账
    getScreenStatistics: p => http.get(`/personnel-service/personnel/getScreenStatistics`, p),//大屏统计
    getYjstatistical: p => http.get(`/warning/event/statistical`, p),//预警事件 - 统计数（大屏）
    getbookList: p => http.get(`/warning/event/today/standing/book/page`, p),//预警事件2 - 列表











}
//环境监测大屏
// const envApi = {
//     queryDeviceWeatherData: (p) => http.get('/device/deviceWeatherData/page', p),	//历史异常天气汇总
//     queryDeviceHoursEnviData: (p) => http.get('/device/deviceEnviData/page', p),	//近24小时数据
//     queryEnvWarningType: (p) => http.get('/alarm1/alarmRecord/getWarningType', p),	//设备预警类型统计
//     queryEnvWarningLevel: (p) => http.get('/alarm1/alarmRecord/getWarningLevel', p),	//设备预警等级统计
//     queryDeviceEnviWarnData: (p) => http.get('/device/deviceEnviWarnData/page', p),	//设备预警等级统计
//     queryDeviceEnviCurData: (p) => http.get('/device/deviceEnviCurData/page', p),	//中央环境监控
//     queryDeviceWeatherDataFuture: (p) => http.get('/device/deviceWeatherData/future', p),	//未来三条天气
// }

//环境监测大屏
const envApi = {
    queryDeviceWeatherData: (p) => http.get('/device/deviceWeatherData/page', p),	//历史异常天气汇总
    queryDeviceHoursEnviData: (p) => http.get('/device/deviceEnviData/page', p),	//近24小时数据
    queryEnvWarningType: (p) => http.get('/alarm1/alarmRecord/getWarningType', p),	//设备预警类型统计
    // queryEnvWarningLevel: (p) => http.get('/alarm1/alarmRecord/getWarningLevel', p),	//设备预警等级统计
    queryEnvWarningLevel: (p) => http.get('/warning/event/v1/getEnvWarningType', p),	//设备预警等级统计

    queryDeviceEnviWarnData: (p) => http.get('/device/deviceEnviWarnData/page', p),	//设备预警等级统计
    // queryDeviceEnviCurData: (p) => http.get('/device/deviceEnviCurData/page', p),	//中央环境监控
    queryDeviceEnviCurData: (p) => http.get('/device/deviceEnviCurData/list', p),	//中央环境监控

    queryDeviceWeatherDataFuture: (p) => http.get('/device/deviceWeatherData/future', p),	//未来三条天气
    getConstructionReminders: (p) => http.get('/warning/event/v1/getEnvironmentReminders', p),//获取施工提醒列表
    getEnvironmentWarns: (p) => http.get('/warning/event/v1/getEnvironmentWarns', p),//获取设备预警列表
    getDeviceWeatherData: p => http.get('/device/deviceWeatherData/getToday', p)//当天天气
}
export default Object.assign({}, passagewaygApi, towerApi, webThingApi, publicMontiorApi, envApi)

