<script>
	// import ScanningLight from '@/components/animation/ScanningLight'
	// import FlowLight from '@/components/animation/FlowLight'
	export default {
		name: 'HeaderDecoration',
		components: {
			// ScanningLight,
			// FlowLight
		},
		props: {
			title: {
				type: String,
				default: ''
			}
		}
	}
</script>

<template>
	<div class="header-decoration">
		<div class="header-title">
			{{ title }}
		</div>
		<!-- <div class="header-scanning">
			<ScanningLight />
		</div>
		<div class="header-flow">
			<FlowLight />
		</div> -->
	</div>
</template>

<style lang="scss" scoped>
	.header-decoration {
		height: 90px;
		background: url('../assets/img/app_header_bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		position: relative;
		.header-title {
			padding-top: 20px;
			text-align: center;
			line-height: 1;
			background: linear-gradient(180deg, #ffffff 50%, #dbefff, #279ffe 79%);
			background-clip: text;
			color: transparent;
			font-family: biaotihei;
			font-size: 30px;
			letter-spacing:4px;
			text-shadow: 0px 2px 6px rgba(39, 159, 254, 0.72);
		}
		.header-scanning {
			width: 1920px;
			height: 79px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
		.header-flow {
			width: 1917px;
			height: 62px;
			position: absolute;
			top: 16px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
</style>
