<template>
  <section style="height: 100%">
    <header-template />
    <section class="app-container">
      <!-- 左侧导航菜单 -->
      <slider-bar-template class="slider-container" />
      <!-- <div class="menu-container">
        <LeftMenu :title="title" :menuList="menus" />
      </div> -->


      <!-- 右侧内容区域 -->
      <!-- <section class="body-container">
        <a-row class="child-page-title">
          <a v-if="isHasBack" class="back" @click="$router.back()"
            ><a-icon type="rollback" /> 返回</a
          >
          <span :class="!isHasBack ? 'title-before' : ''">{{
            $route.meta.title
          }}</span>
        </a-row>
        <router-view class="child-page-container" />
      </section> -->
      <BaseContentBox class="body-container" :showBackButon="isHasBack"
        :width="180"
        :title="$route.meta.title">
        <router-view class="child-page-container" />
      </BaseContentBox>

    </section>
  </section>
</template>

<script>
import headerTemplate from "./header";
import sliderBarTemplate from "./sliderbar";
import BaseContentBox from "@/components/BaseContentBox";
export default {
  components: { headerTemplate,sliderBarTemplate,BaseContentBox },
  data() {
    return {
      user_menus: [],
      selectKey: [],
      openKeys: [],
      title:''
    };
  },
   created() {
    this.openKeys = this.menus
      .filter((item) => item.children && item.children.length > 0)
      .map((item) => item.name);
    this.getSelectKey(this.$route);
    this.defaultSelectedKeys = [this.$route.name];

    let _p = this.$route.path.split('/')[1]
    console.log(_p)
    if(_p == 'pcms'){
      this.title = '人员管理系统';
    }else if(_p == 'pcms'){
      this.title = '';
    }
    else if(_p == 'mdms'){
      this.title = '设备管理系统';
    }
    else if(_p == 'mms'){
      this.title = '材料管理管理系统';
    }
    else if(_p == 'spms'){
      this.title = '法规宣导管理系统';
    }
    else if(_p == 'qsms'){
      this.title = '质量监督管理系统';
    }
    else if(_p == 'ssms'){
      this.title = '安全监督管理系统';
    }
    else if(_p == 'pims'){
      this.title = '项目信息管理系统';
    }
    else if(_p == 'vedioMonitor'){
      this.title = '视频监控';
    }
    else if(_p == 'collision-alarm'){
      this.title = '预警中心';
    }
    else{
      this.title = '';
    }
    // this.title= this.$router.options.routes.filter((item) => item.name == _p)[0].title
    // this.title= this.$router.options.routes.filter(item => item.name == _p)[0].children
    // console.log('=============',this.title);
  },

  computed: {
    currentRoute() {
      return this.$route;
    },
    isHasBack() {
      return (
        this.$route.name.includes("details") ||
        this.$route.name.includes("add") ||
        this.$route.name.includes("edit") ||
        this.$route.query.type==1
      );
    },
    // 新增
    menus() {
      // let _p = this.$route.path.split("/")[1];
      
      // return this.$router.options.routes.filter((item) => item.name == _p)[0]
      //   .children;
      let _p = this.$route.path.split('/')[1]
      return this.$router.options.routes.filter(item => item.name == _p)[0].children
    },
  },
   methods: {
    getSelectKey(router) {
      this.menus.forEach((item) => {
        if (item.children && item.children.length && item.meta.isShowChild) {
          item.children.forEach((v) => {
            if (router.name.includes(v.name)) {
              this.selectKey = [v.name];
            }
          });
        } else {
          if (router.name.includes(item.name)) {
            this.selectKey = [item.name];
          }
        }
      });
    },
  },
  watch: {
    $route: function (router) {
      this.getSelectKey(router);
    },
  },

};
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  height: calc(100% - 104px);
  //height: calc(100% - 84px);
  padding: 0 10px;
  margin-top: 20px;
  .slider-container {
    width: 260px;
    // background: url("../../assets/image/bg-image/bg-sliderbar.png") no-repeat;
    background-size: 100% 100%;
    // padding: 16px;
    background: linear-gradient(180deg, rgba(10, 51, 71, 0.09) 0%, rgba(46, 132, 219, 0.2) 100%);
  }
  .body-container {
    width: calc(100% - 260px);
    height: 100%;
    margin-left: 16px;
    // background: url("../../assets/image/bg-image/bg-content.png") no-repeat;
    background: linear-gradient(180deg, rgba(10, 51, 71, 0) 0%, rgba(10, 51, 71, 0.1) 0%, rgba(46, 132, 219, 0.2) 100%);
    background-size: 100% 100%;
    position: relative;
    .child-page-container {
      margin: 60px 0px 0px 0px;
      padding: 0 40px;
      padding-bottom: 10px;
      height: calc(100% - 80px);
      overflow-y: auto;
      overflow-x: hidden;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .child-page-title {
      position: absolute;
      top: 32px;
      left: 50px;
      font-size: 16px;
      // color: rgba(82,154,255,1);

      display: inline-block;
      font-weight: bold;
      letter-spacing: 2px;
      span {
        color: rgb(82, 154, 255);
      }
      .back {
        margin-right: 16px;
        font-size: 15px;
        color: rgba(82, 154, 255, 0.75);
      }
      .title-before {
        &::before {
          content: "";
          width: 9px;
          display: inline-block;
          height: 9px;
          // background: #84ffe9;
          background: #529aff;
          margin-right: 8px;
          border-radius: 1px;
        }
      }
    }
  }
}
</style>
