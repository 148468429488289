/*
    content:材料管理系统接口列表
    time:2020-10-12

*/


import http from '../http'
// 材料管理
const materialsMngApi = {
    // 物资管理
    //分类修改
    editCategory: p => http.put('/material/category', p),
    // 分类列表
    getCategoryList: p => http.get('/material/category/list', p),
    // 材料SKU列表
    getSkuList: p => http.get('/material/sku/page', p),
    // 库存查询
    getQty: p => http.get('/material/stock/getQty', p),
    // 分类删除
    deleteCategory: p => http.deleteParams('/material/category', p),
    // 分类新增/material/category  /material/info
    addCategory: p => http.postJson('/material/category', p),
    // 分类详情
    getCategoryDetail: p => http.get('/material/category', p),
    // 材料修改
    editMaterial: p => http.put('/material/info', p),
    //材料列表
    getMaterialList: p => http.get('/material/info/page', p),
    // 删除材料
    deleteMaterial: p => http.deleteParams('/material/info', p),
    // 新增材料
    addMaterial: p => http.postJson('/material/category', p),
    // 材料详情
    getMaterialDetail: p => http.get('/material/info', p),
    // 材料新增 material/info
    addMaterialinfo: p => http.postJson('/material/info', p),
    // 材料删除 material/info
    deleteMaterialinfo: p => http.deleteParams('/material/info', p),
    // 材料sku删除 material/info
    deleteMaterialsku: p => http.deleteParams('/material/sku', p),
    // 材料删除 /material/info/{id}
    getMaterialInfoDetail: p => http.getParams('/material/info', p),
    // 材料修改
    editMaterialInfoDetail: p => http.put('/material/info', p),


}
//采购申请
const purchaseMngApi = {
    // 采购申请列表
    getOrderList: p => http.get('/material/order/page', p),
    // 采购清单导出
    getOrderExport: p => http.get('/material/order/export', p),
    // 采购单关闭  
    closeOrder: p => http.putParams('/material/order/close', p),
    // 删除采购单 deleteParams
    deleteOrder: p => http.deleteParams('/material/order', p),
    // 采购编号
    getOrderNextCode: p => http.get('/material/order/getNextCode', p),
    // 采购新增
    addOrder: p => http.postJson('/material/order', p),
    // 批次详情
    getOrderDetail: p => http.getParams('/material/order', p),
    // 批次编号
    getBatchNextCode: p => http.get('/material/batch/getNextCode', p),
    // 批次新增
    addBatchOrder: p => http.postJson('/material/batch', p),
    // 采购编号列表
    getCodeList: p => http.get('/material/order/codeList', p),
    // 批次编号列表
    getBatchCodeList: p => http.get('/material/batch/codeList', p),
    // 批次详情
    getBatchDetail: p => http.getParams('/material/batch', p),
    // 入库新增
    addInStorage: p => http.postJson('/material/inStorage', p),
}

//出入库管理
const warehouseInOutMngApi = {
    // 出入库管理
    
    // 入库单编码
    getInStorageNextCode: p => http.get('/material/inStorage/getNextCode', p),
    // 入库新增
    addInStorage: p => http.postJson('/material/inStorage', p),
    // 入库单详情
    inStorageDetail: p => http.getParams('/material/inStorage', p),
    // 入库记录列表
    getInStorageList: p => http.get('/material/inStorage/page', p),
    // 入库记录导出
    inStorageExport: p => http.get('/material/inStorage/export', p),
    // 出库单编码
    getOutStorageNextCode: p => http.get('/material/outStorage/getNextCode', p),
    // 出库新增
    addOutStorage: p => http.postJson('/material/outStorage', p),
    // 出库单详情
    outStorageDetail: p => http.getParams('/material/outStorage', p),
    // 出库记录列表
    getOutStorageistList: p => http.get('/material/outStorage/page', p),
    // 出库记录导出
    outStorageExport: p => http.get('/material/outStorage/export', p),
    // 库存列表
    getStockList: p => http.get('/material/stock/page', p),
    // 库存基本信息
    getStockDetail: p => http.getParams('/material/stock', p),
    // 库存导出
    stockExport: p => http.get('/material/stock/export', p),
    //SKU入库记录列表
    getInStorageDetailList: p => http.get('/material/inStorageDetail/page', p),
    //SKU出库记录列表
    getOutStorageDetailList: p => http.get('/material/outStorageDetail/page', p),
}

//仓库管理
const warehouseMngApi = {
    // 仓库
    // 仓库下拉
    warehouseData: p => http.get('/material/warehouse/list', p),
    // 仓库列表
    warehouseList: p => http.get('/material/warehouse/page', p),
    // 新增仓库
    addwarehouse: p => http.postJson('/material/warehouse', p),
    // 仓库删除
    deletewarehouse: p => http.deleteParams('/material/warehouse', p),
    // 仓库详情
    detailsWarehouse: p => http.getParams('/material/warehouse', p),
    // 修改仓库
    editWarehouse: p => http.put('/material/warehouse', p),



}

export default Object.assign({}, purchaseMngApi, warehouseInOutMngApi, warehouseMngApi, materialsMngApi)