import http from '../api/index'
//登录
const Login = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        http.login(data)
            .then((res) => {
                if (res.code === 200) {
                    let _data = res.data
                    commit(
                        'SET_AUTHORIZATION',
                        _data.token_type + _data.access_token
                    )
                    commit('SET_USERINFO', _data)
                    resolve(_data)
                } else {
                    reject()
                }
            })
            .catch(() => {
                reject()
            })
    })
}
//退出登录
const Logout = ({ commit }) => {
    return new Promise((resolve, reject) => {
        http.logout().then((res) => {
            if (res.code === 200) {
                commit('INITIAL_STATE')
                resolve()
            } else {
                reject()
            }
        })
    })
}

//图片地址
const QueryPicServer = ({ commit }, data) => {
    return new Promise((resolve) => {
        http.queryPicServer(data).then((res) => {
            if (res.code === 200) {
                let _data = res.data
                commit('SET_PICSERVER', _data.pic_server)
                resolve()
            }
        })
    })
}

export { Login, Logout, QueryPicServer }
