/*
    content:机械设备管理系统接口列表
    time:2020-10-12

*/


import http from '../http'

//设备管理
const deviceMngApi = {
    // 设备管理列表  /device/page?monthAlarmsAsc=false
    getDeviceList: p => http.get('/device/device/page', p),
    // 设备分类列表
    deviceTypeList: p => http.get('/device/deviceType/list', p),
    // 设备管理列表  /device/page?monthAlarmsAsc=false
    getDevice: p => http.get('/device/device/list', p),
    // 设备管理列表  /device/page?monthAlarmsAsc=false
    getDeviceDetail: p => http.getParams('/device/device', p),
    // 保存设备管理
    editDeviceDetail: p => http.putJson('/device/device', p),
    getDeviceElectricityData: (p) => http.get('/device/deviceElectricity/page', p), //获取用电监测台账列表
    getDeviceElectricityData2: (p) => http.get('/device/deviceWater/page', p), //获取用电监测台账列表

    getDeviceEnviData: (p) => http.get('/device/deviceEnviData/page', p) //获取环境台账列表

}

// 维保任务
const serviceApi = {
    // 维保任务列表
    getServiceDataList: p => http.get('/device/deviceMaintenance/page', p),
    // 新增维保任务
    addServiceData: p => http.postTransJson('/device/deviceMaintenance', p),
    // 查看维保任务详情/
    deviceMaintenance: p => http.get('/device/deviceMaintenance/detail', p),
    // 查看进度详情
    processDetail: p => http.get('/device/deviceMaintenance/processDetail', p),

    // 待处理-提交
    approveDevice: p => http.postJson('/device/deviceMaintenance/approve', p),
    // 待验收-同意
    approveDevices: p => http.postJson('/device/deviceMaintenance/approve', p),
    // 待验收-拒绝
    rejectDevices: p => http.postJson('/device/deviceMaintenance/reject', p),
    // 档案查询
    deviceMaintenanceList: p => http.get('/device/deviceMaintenance/pageRecord', p),

}
// 出入口管理
const exitApi = {
    // 出入口列表
    doorwayList: p => http.get('/device/doorway/list', p),
    // 出入口详情
    doorwayListDetails: p => http.get(`/device/doorway/${p}`),
     // 出入口删除
    deleteDoorway: p => http.delete(`/device/doorway/${p}`), 
    // 新增
    addDoorway: p => http.postJson('/device/doorway', p),
    // 修改
    updateDoorway: p => http.post('/device/doorway/updateDoorway', p),
}

// 设备预警
const deviceWarnApi = {
    // 设备预警列表  alarm1
    alarmRecordList: p => http.get('/alarm1/alarmRecord/page', p),
    // 预警详情
    alarmRecordDetail: p => http.getParams('/alarm1/alarmRecord', p),
    // 确认处理
    handerRecord: p => http.putJson('/alarm1/alarmRecord', p),
}
export default Object.assign({}, deviceMngApi, serviceApi, exitApi, deviceWarnApi)