<template>
  <div id="micro-app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import axios from 'axios'
  export default {
    data() {
      return {
        locale: zhCN
      }
    },
    // created() {
    //   setInterval(() => {
    //     this.checkVersion()
    //   }, 5000)
    // },
    methods: {
      checkVersion() {
        //版本校验
        axios
          .get(location.origin + '/static/version.json', {
            headers: {
              'Cache-Control': 'no-cache'
            }
          })
          .then((res) => {
            let version = res.version
            const clientVersion = localStorage.getItem('_version_')
            if (version !== clientVersion) {
              localStorage.setItem('_version_', version)
              window.location.reload()
            }
          })
      }
    }
  }
</script>

<style lang="scss">
  #micro-app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #029ADC 0%, #0256A0 0%, #0C2B46 100%);
    // background: url('./assets/image/bg-image/backgroundImg.jpg') no-repeat;
    // background-size: 100% 100%;
    input {
      &:-webkit-autofill {
        color: #ffffff !important;
        box-shadow: 0 0 0px 1000px #4D7DAC inset !important;
        -webkit-text-fill-color: #ffffff !important;
      }
    }
  }
</style>
