const baseURL = 'warning' // 安全系统
// const basic = 'basic' //组织架构
const userBaseURL = '/user' // 组织架构等
// const personBaseURL = 'api'
// const postURL = '/project' // 岗位名称等
// const baseURL = '/safe' // 安全系统
// 
import http from '../http'
//安全巡检
const yujingApi = {
    getAlarmEventList: p => http.get(`${baseURL}/event/page`, p),
    getAlarmEvenDetail: p => http.get(`${baseURL}/event/${p}`),
    saveAlarmEvent: p => http.put(`${baseURL}/event/dispose/save`, p),
    submitAlarmEvent: p => http.put(`${baseURL}/event/dispose/submit`, p),
    getAlarmRuleList: p => http.get(`${baseURL}/rule/page`,p),
    setAlarmRule: p => http.put(`${baseURL}/rule`, p),
    enableAlarmRule: p => http.put(`${baseURL}/rule/enable`, p),
    getAlarmRuleBaseList: p => http.get(`${baseURL}/ruleBase/page`, p),
    getAlarmRuleIssueDetail: p => http.get(`${baseURL}/ruleBase/issued/${p}`, p),
    insertAlarmRule2: p => http.put(`${baseURL}/ruleBase/add`, p),
    updateAlarmRule: p => http.put(`${baseURL}/ruleBase`, p),
    removeAlarmRule: p => http.put(`${baseURL}/ruleBase/remove`, p),
    issueAlarmRule: p => http.postJson(`${baseURL}/ruleBase/issue`, p), 
    getPushRuleList: p => http.get(`${baseURL}/pushRule/page`, p),
    getPushRuleDetail: p => http.get(`${baseURL}/pushRule/${p}`),
    setPushRule: p => http.put(`${baseURL}/pushRuleSituation`, p),
    getStateGradeList: () => http.get(`${baseURL}/levelSituation`),
    setStateGrade: p => http.put(`${baseURL}/levelSituation`, p),
    getDictList: p => http.get(`${baseURL}/dictList/type/${p}`),
    getOrganStructure: () => http.get(`${userBaseURL}/tenantOrg/getMyTenantOrgList`),
}


export default Object.assign({}, yujingApi)