/*
    content:大数据应用平台接口列表
    time:2020-11-05

*/

import http from '../http'

const bigdataApi = {
	// 预警类型
	getAlarmTypeAll: (p) => http.get('/alarm1/alarmType/list', p),
	queryAlarmType: (p) => http.get('/alarm1/alarmType/page', p),
	insertAlarmType: (p) => http.postJson('/alarm1/alarmType', p),
	updateAlarmType: (p) => http.put('/alarm1/alarmType', p),
	deleteAlarmType: (p) => http.deleteParams('/alarm1/alarmType', p),

	// 预警等级
	getAlarmGradeAll: (p) => http.get('/alarm1/alarmGrade/list', p),
	queryAlarmGrade: (p) => http.get('/alarm1/alarmGrade/page', p),
	insertAlarmGrade: (p) => http.postJson('/alarm1/alarmGrade', p),
	updateAlarmGrade: (p) => http.put('/alarm1/alarmGrade', p),
	deleteAlarmGrade: (p) => http.deleteParams('/alarm1/alarmGrade', p),

	// 预警规则
	queryAlarmRule: (p) => http.get('/alarm1/alarmRule/pageT', p),
	handleAlarmRule: (p) => http.postJson('/alarm1/alarmRule/add', p),
	// queryAlarmRuleDetail: (p) => http.get('/alarm1/alarmRuleDetail/page', p),
	// enableAlarmRule: (p) => http.put('/alarm1/alarmRuleDetail', p),
	handleAlarmRuleDetail: (p) => http.put('/alarm1/alarmRule', p),
	insertAlarmRule: (p) => http.get('/alarm1/alarmRule/page', p),
	// setAlarmRule: (p) => http.put('/alarm1/alarmRuleDetail', p),
	// copyAlarmRule: (p) => http.postJson('/alarm1/alarmRuleDetail', p),

	// 推送规则
	getAlarmPushRuleAll: (p) => http.get('/alarm1/alarmPushRule/list', p),
	queryAlarmPushRule: (p) => http.get('/alarm1/alarmPushRule/page', p),
	insertAlarmPushRule: (p) => http.postJson('/alarm1/alarmPushRule', p),
	getAlarmPushRuleDetail: (p) => http.getParams('/alarm1/alarmPushRule', p),

	// 预警中心
	queryAlarmEvent: (p) => http.get('/alarm1/alarmRecord/page', p),
	getAlarmEventDetail: (p) => http.getParams('/alarm1/alarmRecord', p),
	handleAlarmEvent: (p) => http.put('/alarm1/alarmRecord', p)
}

export default Object.assign({}, bigdataApi)
