/*
    content:人员车辆管理系统接口列表
    time:2020-10-12

*/ 


import http from '../http'

//劳务管理
const labourMngApi = {
    //人员管理
    queryPersonnelList: p => http.get('/personnel/personnel/getPersonnel', p), //人员-列表
    queryPersonnelDetails: p => http.get('/personnel/personnel/details', p), //人员-详情
    insertPersonnel: p => http.postJson('/personnel/personnel/addPersonnel', p), //人员-添加
    updatePersonnel: p => http.postJson('/personnel/personnel/updatePersonnel', p), //人员-编辑
    personnelEntrance: p => http.put('/personnel/personnel/personnelEntrance', p), //人员-在入场
    personnelReEntrance:p=> http.get('/data/facility/reEntry?id='+p), //人员-设备再绑定
    deletePersonnel: p => http.delete('/personnel/personnel', p), //人员-删除
    unBindFaceDevice:p=> http.delete('/data/facility/unBindFaceDevice?id='+p), //人员-解绑

    getFaceDeviceCode:p=> http.get('/personnel/personnelFaceCollection/getFaceDeviceCode?personnelId='+p), //人员

    addPersonnelBatch: p => http.postJson('/personnel/personnel/addPersonnelBatch', p),//人员登记-批量导入信息


    personnelExit: p => http.put('/personnel/personnel/personnelExit', p), //人员-退场
    queryPersonnelExitLog: p => http.get('/personnel/personnel/getPersonnelExitLog', p), //人员-退场记录
    queryAbnormalList: p => http.get('/personnel/personnelAbnormal/getAbnormalList', p), //人员-异常数据
    queryPersonnelIssueLog: p => http.get('/personnel/personnel/getPersonnelIssueLog', p), //人员-下发记录
    queryPersonnelAttendanceRecordList: p => http.get('/personnel/attendanceRecord/getAttendanceRecordList', p), //人员-考勤记录
    

    //批量退场
    personnelBatchExit: p => http.put('/personnel/personnel/personnelBatchExit', p), //人员-批量退场
    batchUnBindFaceDevice:p=> http.put('/data/facility/unBindFaceDevice/batch',p), //人员-批量解绑
    
    queryPhotograph: p => http.get('/data/facility/photograph', p), //人员-拍照
    getTakeImage: p => http.get('/data/facility/getTakeImage', p), //人员-获取照片
    personnelIssued: p => http.get('/data/facility/personnelIssued', p), //人员-下发接口
    delPersonnelIssued: p => http.get('/data/facility/delPersonnel', p), //人员-下发删除接口
    

    //考勤管理
    queryPersonnelFaceCollectionLog: p => http.get('/personnel/personnelFaceCollectionLog/page', p), //考勤记录-列表
    queryAttendanceSettingList: p => http.get('/personnel/attendanceSetting/page', p), //考勤设置-列表
    deleteAttendanceSetting: p => http.delete(`/personnel/attendanceSetting/delSetting`,p), //考勤设置-删除
    insertAttendanceSetting: p => http.postJson('/personnel/attendanceSetting/addSetting',p), //考勤设置-新增
    updateAttendanceSetting: p => http.postJson('/personnel/attendanceSetting/updateSetting',p), //考勤设置-编辑

    //值班管理
    queryDutyApplyList: p => http.get('/personnel/dutyApply/page', p), //值班-列表
    deleteDutyApply: p => http.delete(`/personnel/dutyApply/${p}`), //值班-删除
    insertDutyApply: p => http.postJson(`/personnel/dutyApply`,p), //值班-添加
    updateDutyApply: p => http.put(`/personnel/dutyApply`,p), //值班-编辑
    queryDutyApplyDetails: p => http.get(`/personnel/dutyApply/${p}`), //值班-详情

    //请假
    queryAskForLeaveList: p => http.get('/personnel/askForLeave/page', p), //请假-列表
    queryAskForLeaveDetails: p => http.get(`/personnel/askForLeave/${p}`), //请假-详情
    queryAskForTypeList: p => http.get(`/personnel/askForType/page`,p), //请假-类型
    insertAskForLeave: p => http.postJson(`/personnel/askForLeave`,p), //请假-添加
    updateAskForLeave: p => http.put(`/personnel/askForLeave`,p), //请假-编辑
    deleteAskForLeave: p => http.delete(`/personnel/askForLeave/${p}`), //请假-删除
    askForApprovePerson: p => http.postJson(`/personnel/askForApprovePerson`,p), //请假-审批人配置
    askForLeaveLog: p => http.postJson(`/personnel/askForLeaveLog`,p), //请假-审批

    //薪资管理
    
    queryCompanyPaySalaryPersonnelList: p => http.get('/personnel/companyPaySalaryPersonnel/page', p), //薪资统计-列表
    queryCompanyPaySalaryPersonnelStatistics: p => http.get('/personnel/companyPaySalaryPersonnel/statistics', p), //薪资统计-列表
    salaryTotalPayment: p => http.postJson('/personnel/companyPaySalaryTotal/payment', p), //薪资统计-工资生成

    queryCompanyPaySalaryConfigList: p => http.get('/personnel/companyPaySalaryConfig/page', p), //工资配置-列表
    insertCompanyPaySalaryConfig: p => http.postJson('/personnel/companyPaySalaryConfig', p), //工资配置-添加
    updateCompanyPaySalaryConfig: p => http.put('/personnel/companyPaySalaryConfig', p), //工资配置-修改
    deleteCompanyPaySalaryConfig: p => http.delete(`/personnel/companyPaySalaryConfig/${p}`), //工资配置-删除
    queryCompanyPaySalaryConfigDetails: p => http.get(`/personnel/companyPaySalaryConfig/${p}`), //工资配置-详情

    queryCompanyPaySalaryTotalList: p => http.get('/personnel/companyPaySalaryTotal/page', p), //工资发放-列表
    queryCompanyPaySalaryTotalStatistics: p => http.get('/personnel/companyPaySalaryTotal/statistics', p), //工资发放-统计
    queryCompanyPaySalaryTotalPersonnelList: p => http.get('/personnel/companyPaySalaryTotalPersonnel/page', p), //工资发放-详情表-列表

    updateCompanyPaySalaryTotalPersonnel: p => http.put('/personnel/companyPaySalaryTotalPersonnel', p), //工资发放-详情表-编辑
    queryCompanyPaySalaryTotalDetails: p => http.get(`/personnel/companyPaySalaryTotal/${p}`), //工资发放-详情
    deleteCompanyPaySalaryTotal: p => http.delete(`/personnel/companyPaySalaryTotal/${p}`), //工资发放-删除
    updateCompanyPaySalaryTotal: p => http.put(`/personnel/companyPaySalaryTotal`,p), //工资发放-删除

    //工资类型
    queryCompanyPayTypeList: p => http.get('/personnel/companyPayType/page', p), //工资配置-类型-列表


    //黑名单
    queryBlackList: p => http.get('/personnel/inspection/person/page', p), //人员黑名单-列表
    queryBlackDetails: p => http.get(`/personnel/inspection/person/get?id=${p}`), //人员黑名单-详情
    insertBlack: p => http.postJson('/personnel/inspection/person/save', p), //人员黑名单-添加
    relieveBlack: p => http.postJson('/personnel/inspection/person/relieve', p), //人员黑名单-解除
    queryPersonnelIsBlack: p => http.get(`/personnel/inspection/person/isBlacklist?idCard=${p}`), //人员查询是否是黑名单人员
    


    

}


//车辆管理
const carMngApi = {
    //车辆类型
    queryCarTypeList: p => http.get('/personnel/carType/page', p), //车辆类型-列表
    queryCarTypeDetails: p => http.get('/personnel/carType/details', p), //车辆类型-详情
    insertCarType: p => http.postJson('/personnel/carType', p), //车辆类型-添加
    updateCarType: p => http.put('/personnel/carType', p), //车辆类型-编辑
    deleteCarType: p => http.delete('/personnel/carType', p), //车辆类型-删除

    //出入口档案
    queryCarInletOutletList: p => http.get('/personnel/carInfo/getCarInletOutlet', p), //出入口-列表
    queryCarInletOutletDetails: p => http.get('/personnel/carInfo/getCarInletOutletDetails', p), //出入口-详细

    //车辆档案
    queryCarInfoList: p => http.get('/personnel/carInfo/getCarInfoList', p), //车辆档案-列表
    queryCarInfoDetails: p => http.get('/personnel/carInfo/details', p), //车辆档案-列表
    insertCarInfo: p => http.postJson(`/personnel/carInfo`, p), //车辆档案-添加
    updateCarInfo: p => http.put(`/personnel/carInfo`, p), //车辆档案-编辑
    deleteCarInfo: p => http.delete(`/personnel/carInfo/${p}`), //车辆档案-删除
    removeByAllCarInfo: p => http.delete(`/personnel/carInfo/removeByAll`,p), //车辆档案-批量删除

    //出入口记录
    queryCarLogList: p => http.get('/personnel/carInOutLog/getCarLogList', p), //出入口记录-列表

    addCarBatch: p => http.postJson('/personnel/carInfo/import', p),//车辆登记-批量导入信息

}

//基本配置

const configApi = {

}

export default Object.assign({},labourMngApi,carMngApi,configApi)