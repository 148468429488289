<template>
    <section style="height:100%">
        <header-template />
        <router-view class="app-container"/>
    </section>
</template>

<script>
import headerTemplate from './header'

export default {
    components:{headerTemplate},
    computed:{
        currentPageTitle(){
            return this.$route.meta.title
        }
    }
}
</script>

<style lang="scss" scoped>
.app-container{
    height: calc( 100% - 104px);
    padding: 0 10px;
    margin-top: 20px;

    // overflow: auto;
}
    
</style>