<template>
  <section class="header-container">
    <a-row>
      <!-- 左侧时间天气 -->
      <a-col span="7">
        <a-row type="flex" align="middle">
          <!-- <a-col class="m-l-16">
            <img style="width: 150px; margin-left: 18px" src="../../assets/image/icon/logo.png" alt />
          </a-col> -->
        </a-row>
      </a-col>
      <!-- 中间标题 -->
      <a-col span="9" class="header-title">
        <!-- <img style="width:400px;margin-top:8px" src="../../assets/image/header/header.png" alt=""> -->
        <!-- <h3>智慧营区管理云平台</h3> -->
        <h3>会展西路过江隧道工程营区信息化管理云平台</h3>
        <span>Intelligent camp management platform</span>
      </a-col>
      <!-- 右侧操作部分 -->
      <a-col span="8" class="header-right">
        <a-row class="item wrap-time">
          <span>{{ currentTime }}</span>
        </a-row>
        <a-row class="item wrap-date">
          <p class="week">{{ currentWeek }}</p>
          <p>{{ currentDate }}</p>
        </a-row>
        <a-row class="item wrap-weather">
          <p class="weather">{{weather}}</p>
          <p>{{temp_low}}℃~{{temp_high}}℃</p>
        </a-row>
        <!-- <router-link
          class="item"
          to="/monitor"
          v-if="currentRoute.path != '/monitor/index'"
        >
          <a-tooltip title="中央大屏"
            ><a-icon style="font-size: 20px" type="laptop"
          /></a-tooltip>
        </router-link>-->
        <router-link class="item" to="/home">
          <a-tooltip title="工作台">
            <a-icon style="font-size: 20px" type="appstore" />
          </a-tooltip>
        </router-link>
        <a class="item" @click="fullScreenClick">
          <a-tooltip :title="!fullScreen ? '全屏' : '退出全屏'">
            <a-icon style="font-size: 20px" :type="fullScreen ? 'fullscreen-exit' : 'fullscreen'" />
          </a-tooltip>
        </a>
        <a class="item" @click="logout">
          <a-tooltip title="安全退出">
            <a-icon style="font-size: 20px" type="logout" />
          </a-tooltip>
        </a>
        <span class="item">
          <a-avatar class="m-r-8" icon="user"></a-avatar>
          <!-- <span>{{ userNickname }}</span> -->
          <div class="username">
            <div>{{ userInfo.userNickname }}</div>
            <div>{{ userInfo.phone }}</div>
          </div>
        </span>
      </a-col>
    </a-row>
    <!-- <a-row class="header-border">
      <img src="../../assets/image/header/icon.png" alt="" />
    </a-row>-->
  </section>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      timer: "",
      currentTime: "",
      currentDate: "",
      currentWeek: "",
      fullScreen: false,
      weather: '晴转多云',
      temperature: '18℃/25℃',
      temp_low: '18',
      temp_high: '25'
    };
  },
  created() {
    this.getCurrentDate();

    this.getDeviceWeatherData();//获取当天天气
    this.timer = setInterval(() => {
      this.getCurrentDate();
    }, 1000);
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    currentRoute() {
      return this.$route;
    },
  },
  mounted() {
    this.checkFullScreen();
  },

  methods: {
    //天气
    getDeviceWeatherData() {
      this.$api.getDeviceWeatherData().then(res => {
        if (res.code == 200) {
          this.weather = res.data.weather && res.data.weather != '' ? res.data.weather : '晴转多云';
          this.temperature = res.data.temperature && res.data.temperature != '' ? res.data.temperature : '18℃/25℃';
          this.temp_low = res.data.temp_low && res.data.temp_low != '' ? res.data.temp_low : '18';
          this.temp_high = res.data.temp_high && res.data.temp_high != '' ? res.data.temp_high : '25';

        }
      })
    },
    // 监测屏幕状态
    checkFullScreen() {
      const that = this;
      window.onresize = function () {
        if (!that.checkFull()) {
          that.fullScreen = false;
          that.windowExitFullScreen();
        }
      };
    },
    ...mapActions(["Logout"]),
    getCurrentDate() {
      this.currentTime = moment().format("HH:mm");
      this.currentDate = moment().format("YYYY/MM/DD");
      this.currentWeek = "星期" + "日一二三四五六".charAt(new Date().getDay());
    },
    //全屏按钮\退出全屏按钮点击事件
    fullScreenClick() {
      this.fullScreen = !this.fullScreen;
      if (this.fullScreen) {
        //当前要启动全屏
        //this.pageFullScreen();//执行全屏之后的一些操作
        this.windowFullScreen();
      } else {
        //当前要退出全屏
        //this.pageExitFullScreen();//执行退出全屏之后的一些操作
        this.windowExitFullScreen();
      }
    },
    //启动全屏
    windowFullScreen() {
      let docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
    },
    //退出全屏
    windowExitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    },
    //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
    checkFull() {
      //火狐浏览器
      let isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) isFull = false;
      return isFull;
    },

    // fullScreen() {
    //   var element = document.documentElement; //若要全屏页面中div，var element= document.getElementById("divID");
    //   //var element= document.getElementById("fullScreen_panorama");
    //   //IE 10及以下ActiveXObject
    //   if (window.ActiveXObject) {
    //     // eslint-disable-next-line no-undef
    //     var WsShell = new ActiveXObject("WScript.Shell");
    //     WsShell.SendKeys("{F11}");
    //     //写全屏后的执行函数
    //   }
    //   //HTML W3C 提议
    //   else if (element.requestFullScreen) {
    //     element.requestFullScreen();
    //     //写全屏后的执行函数
    //   }
    //   //IE11
    //   else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen();
    //     //写全屏后的执行函数
    //   }
    //   // Webkit (works in Safari5.1 and Chrome 15)
    //   else if (element.webkitRequestFullScreen) {
    //     element.webkitRequestFullScreen();
    //     //写全屏后的执行函数
    //   }
    //   // Firefox (works in nightly)
    //   else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen();
    //     //写全屏后的执行函数
    //   }
    // },
    //退出
    logout() {
      this.$store.commit("INITIAL_STATE");
      this.$nextTick(() => {
        this.$router.push({ path: "/login" });
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //清除我们的定时器
    }
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
/deep/.ant-select-selection:hover {
  border-color: #3f72ba;
}
/deep/.ant-select-selection {
  border: 0;
  background: #3f72ba;
}
/deep/.ant-select {
  color: #1890ff;
}
/deep/.anticon {
  color: #1890ff;
}
.header-container {
  height: 88px;
  background: rgba(20, 34, 89, 0.7);
  position: relative;
  overflow: hidden;
  background: url("../../assets/image/header/icon.png") no-repeat 0 100%;
  background-size: contain;
  .header-border {
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    img {
      width: 100%;
    }
  }
  .header-title {
    text-align: center;
    h3 {
      color: #ffffff;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 6px;
      margin: 1px 0 1px 0;
      padding-top: 3px;
    }
    span {
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 2px;
      font-size: 13px;
    }
  }
  .wrap-time {
    span {
      font-size: 28px;
      font-family: digital;
    }
  }
  .wrap-date {
    text-align: center;
    border-right: 1px solid rgba(148, 148, 148, 0.5);
    padding-right: 24px;
    p {
      font-family: digital;
    }
    .week {
      font-family: digital;
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
  .wrap-weather {
    padding-right: 20px;
    p {
      font-family: digital;
    }
    .weather {
      font-size: 14px;
      letter-spacing: 2px;
      font-family: digital;
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    align-items: center;
    padding-top: 10px;
    .item {
      margin-right: 18px;
      display: inline-block;
      .username {
        float: right;
        font-size: 12px;
      }
    }
  }
}
</style>