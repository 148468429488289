<template>
    <section style="height:100%">
        <!-- <header-template /> -->
        <AppHeader />
        <router-view class="app-container"/>
    </section>
</template>

<script>
// import headerTemplate from './header'
import AppHeader from "./AppHeader";

export default {
    components:{AppHeader},//headerTemplate,
    computed:{
        currentPageTitle(){
            return this.$route.meta.title
        }
    }
}
</script>

<style lang="scss" scoped>
.app-container{
    height: calc( 100% - 90px);
    padding: 0 10px;
    // margin-top: 20px;
    margin-top: 90px;

    // overflow: auto;
}
    
</style>