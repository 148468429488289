import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/index.vue'
import mainHeader from '@/components/layout/mainHeader.vue'
import mainHeader2 from '@/components/layout/mainHeader2.vue'

import tempPage from '@/components/layout/temp.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/login'
    },
    //登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue')
    },
    //自动登录
    {
        path: '/home/unLogin',
        name: 'unLogin',
        component: () => import('../views/login/unLogin.vue')
    },
    // BIM
    {
        path: '/bim',
        name: 'BIM',
        component: () => import('../views/bim/Index')
    },
    //首页
    {
        path: '/home',
        name: 'home',
        component: mainHeader,
        redirect: '/home/ind',
        children: [
            {
                path: '/home/ind',
                name: 'home-index',
                component: () => import('../views/home.vue')
            }
        ]
    },
    // 大屏跳转用电监测台账
    {
        path: 'electricity',
        name: 'electricity',
        component: mainHeader,
        redirect: '/mdms/dpElectricity',
        children: [
            {
                path: '/mdms/dpElectricity',
                name: 'dpElectricity',
                component: () => import('../views/mdms/device/dpElectricity.vue')
            }
        ]
    },
    // 大屏跳转用水监测台账
    {
        path: 'dpwater',
        name: 'dpwater',
        component: mainHeader,
        redirect: '/mdms/dpwater',
        children: [
            {
                path: '/mdms/dpwater',
                name: 'dpwater',
                component: () => import('../views/mdms/device/dpwater.vue')
            }
        ]
    },
    //大屏跳转环境监测台账
    {
        path: 'environment',
        name: 'environment',
        component: mainHeader,
        redirect: '/mdms/dpEnvironment',
        children: [
            {
                path: '/mdms/dpEnvironment',
                name: 'dpEnvironment',
                component: () => import('../views/mdms/device/dpEnvironment.vue')
            }
        ]
    },
    //大屏跳转人员出入台账
    {
        path: 'dpList',
        name: 'dpList',
        component: mainHeader,
        redirect: '/pcms/labour/attendance/dpList',
        children: [
            {
                path: '/pcms/labour/attendance/dpList',
                name: 'dpList',
                component: () =>
                    import('../views/pcms/labour/attendance/dpList.vue')
            }
        ]
    },
    //大屏跳转设备管理
    {
        path: 'device',
        name: 'device',
        component: mainHeader,
        redirect: '/mdms/dpDevice',
        children: [
            {
                path: '/mdms/dpDevice',
                name: 'dpDevice',
                component: () =>
                    import('../views/mdms/device/dpDeviceManger.vue')
            }
        ]
    },
    //大屏跳转预警台账
    {
        path: 'alarm-event',
        name: 'alarm-event',
        component: mainHeader,
        redirect: '/collision-alarm/dpAlarm-event',
        children: [
            {
                path: '/collision-alarm/dpAlarm-event',
                name: 'dpAlarm-event',
                component: () =>
                    import('@/views/collision-alarm/alarm-event/dpIndex')
            }
        ]
    },
    //大屏跳转人员档案
    {
        path: 'personList',
        name: 'personList',
        component: mainHeader,
        redirect: '/pcms/labour/person/dpList',
        children: [
            {
                path: '/pcms/labour/person/dpList',
                name: 'personList',
                component: () =>
                    import('../views/pcms/labour/person/dpList.vue')
            }
        ]
    },

    // 视频监控(vedioMonitor)
    {
        path: '/vedioMonitor',
        name: 'vedioMonitor',
        component: mainHeader,
        redirect: '/monitor/vedioMonitor',
        children: [
            {
                path: '/monitor/vedioMonitor',
                name: 'monitor-vedioMonitor',
                component: () => import('../views/monitor_screen/vedioMonitor.vue')
            }
        ]
    },

    //人员车辆管理系统（pcms）
    {
        path: '/pcms',
        name: 'pcms',
        component: Layout,
        redirect: '/pcms/labour',
        children: [
            {
                path: '/pcms/labour',
                name: 'pcms-labour',
                meta: { title: '人员管理', icon: 'user', isShowChild: true },
                component: tempPage,
                redirect: '/pcms/labour/person',
                children: [
                    // 人员管理
                    {
                        path: '/pcms/labour/person',
                        name: 'pcms-labour-person',
                        meta: { title: '人员档案', isNav: true },
                        component: tempPage,
                        redirect: '/pcms/labour/person/list',
                        children: [
                            {
                                path: '/pcms/labour/person/list',
                                name: 'pcms-labour-person-list',
                                meta: { title: '人员管理' },
                                component: () => import('../views/pcms/labour/person/list.vue')
                            },
                            {
                                path: '/pcms/labour/person/details',
                                name: 'pcms-labour-person-details',
                                meta: { title: '人员信息详情' },
                                component: () =>
                                    import('../views/pcms/labour/person/details.vue')
                            },
                            {
                                path: '/pcms/labour/person/add',
                                name: 'pcms-labour-person-add',
                                meta: { title: '添加人员' },
                                component: () => import('../views/pcms/labour/person/add.vue')
                            },
                            {
                                path: '/pcms/labour/person/edit',
                                name: 'pcms-labour-person-edit',
                                meta: { title: '编辑人员' },
                                component: () => import('../views/pcms/labour/person/add.vue')
                            },
                            {
                                path: '/pcms/labour/person/leave',
                                name: 'pcms-labour-person-leave-details',
                                meta: { title: '人员退场' },
                                component: () => import('../views/pcms/labour/person/leave.vue')
                            },
                            {
                                path: '/pcms/labour/person/entrance',
                                name: 'pcms-labour-person-entrance-details',
                                meta: { title: '人员再入场' },
                                component: () => import('../views/pcms/labour/person/add.vue')
                            },
                            {
                                path: '/pcms/labour/person/leaveRecord',
                                name: 'pcms-labour-person-leaveRecord',
                                meta: { title: '人员离场记录' },
                                component: () =>
                                    import(
                                        '../views/pcms/labour/person/details-component/leaveRecord.vue'
                                    )
                            },
                            {
                                path: '/pcms/labour/person/batchLeave',
                                name: 'pcms-labour-person-batchLeave-details',
                                meta: { title: '批量人员退场' },
                                component: () => import('../views/pcms/labour/person/batchLeave.vue')
                            },
                        ]
                    },
                    // 考勤管理
                    {
                        path: '/pcms/labour/attendance',
                        name: 'pcms-labour-attendance',
                        meta: { title: '出入台账', isNav: true },
                        component: tempPage,
                        redirect: '/pcms/labour/attendance/list',
                        children: [
                            {
                                path: '/pcms/labour/attendance/list',
                                name: 'pcms-labour-attendance-list',
                                meta: { title: '出入台账' },
                                component: () =>
                                    import('../views/pcms/labour/attendance/list.vue')
                            }
                        ]
                    },
                    //值班管理
                    {
                        path: '/pcms/labour/onDuty',
                        name: 'pcms-labour-onDuty',
                        meta: { title: '值班管理', isNav: true },
                        component: tempPage,
                        redirect: '/pcms/labour/onDuty/list',
                        children: [
                            {
                                path: '/pcms/labour/onDuty/list',
                                name: 'pcms-labour-onDuty-list',
                                meta: { title: '值班管理' },
                                component: () => import('../views/pcms/labour/onDuty/list.vue')
                            },
                            {
                                path: '/pcms/labour/onDuty/add',
                                name: 'pcms-labour-onDuty-add',
                                meta: { title: '添加值班' },
                                component: () => import('../views/pcms/labour/onDuty/add.vue')
                            },
                            {
                                path: '/pcms/labour/onDuty/details',
                                name: 'pcms-labour-onDuty-details',
                                meta: { title: '值班详情' },
                                component: () =>
                                    import('../views/pcms/labour/onDuty/details.vue')
                            }
                        ]
                    },
                    // 黑名单
                    {
                        path: '/pcms/labour/blacklist',
                        name: 'pcms-labour-blacklist',
                        meta: { title: '黑名单', isNav: true },
                        component: tempPage,
                        redirect: '/pcms/labour/blacklist/list',
                        children: [
                            {
                                path: '/pcms/labour/blacklist/list',
                                name: 'pcms-labour-blacklist-list',
                                meta: { title: '黑名单' },
                                component: () =>
                                    import('../views/pcms/labour/blacklist/list.vue')
                            },
                            {
                                path: '/pcms/labour/blacklist/add',
                                name: 'pcms-labour-blacklist-add',
                                meta: { title: '黑名单/添加人员' },
                                component: () => import('../views/pcms/labour/blacklist/add.vue')
                            },
                            {
                                path: '/pcms/labour/blacklist/details',
                                name: 'pcms-labour-blacklist-details',
                                meta: { title: '黑名单/人员详情' },
                                component: () =>
                                    import('../views/pcms/labour/blacklist/details.vue')
                            },
                            {
                                path: '/pcms/labour/blacklist/relieve',
                                name: 'pcms-labour-blacklist-relieve-details',
                                meta: { title: '黑名单/解除限制' },
                                component: () =>
                                    import('../views/pcms/labour/blacklist/relieve.vue')
                            },
                            {
                                path: '/pcms/labour/blacklist/activation',
                                name: 'pcms-labour-blacklist-activation-details',
                                meta: { title: '黑名单/激活' },
                                component: () =>
                                    import('../views/pcms/labour/blacklist/activation.vue')
                            }
                        ]
                    },
                    //请假申请
                    // {
                    //   path: '/pcms/labour/leaveApplication',
                    //   name: 'pcms-labour-leaveApplication',
                    //   meta: { title: '请假申请', isNav: true },
                    //   component: tempPage,
                    //   redirect: '/pcms/labour/leaveApplication/list',
                    //   children: [
                    //     {
                    //       path: '/pcms/labour/leaveApplication/list',
                    //       name: 'pcms-labour-leaveApplication-list',
                    //       meta: { title: '请假申请' },
                    //       component: () =>
                    //         import('../views/pcms/labour/leaveApplication/list.vue')
                    //     },
                    //     {
                    //       path: '/pcms/labour/leaveApplication/add',
                    //       name: 'pcms-labour-leaveApplication-add',
                    //       meta: { title: '添加请假申请' },
                    //       component: () =>
                    //         import('../views/pcms/labour/leaveApplication/add.vue')
                    //     },
                    //     {
                    //       path: '/pcms/labour/leaveApplication/edit',
                    //       name: 'pcms-labour-leaveApplication-edit',
                    //       meta: { title: '编辑请假申请' },
                    //       component: () =>
                    //         import('../views/pcms/labour/leaveApplication/add.vue')
                    //     },
                    //     {
                    //       path: '/pcms/labour/leaveApplication/details',
                    //       name: 'pcms-labour-leaveApplication-details',
                    //       meta: { title: '查看请假申请' },
                    //       component: () =>
                    //         import('../views/pcms/labour/leaveApplication/details.vue')
                    //     }
                    //   ]
                    // }
                    // 薪资发放
                    // {
                    //   path:'/pcms/labour/salary',
                    //   name:'labour-salary',
                    //   meta:{title:'薪资发放',isNav:true},
                    //   component:tempPage,
                    //   redirect:'/pcms/labour/salary/list',
                    //   children:[
                    //     {
                    //       path:'/pcms/labour/salary/list',
                    //       name:'labour-salary-list',
                    //       meta:{title:'薪资发放'},
                    //       component:() => import("../views/pcms/labour/salary/list.vue")
                    //     }
                    //   ]
                    // }
                ]
            },
            // {
            //     path: '/pcms/salary',
            //     name: 'pcms-salary',
            //     meta: { title: '薪资发放', icon: 'pound', isShowChild: true },
            //     component: tempPage,
            //     redirect: '/pcms/salary/setting',
            //     children: [
            //         //薪资设置
            //         {
            //             path: '/pcms/salary/setting',
            //             name: 'pcms-salary-setting',
            //             component: tempPage,
            //             meta: { title: '薪资设置', isNav: true },
            //             redirect: '/pcms/salary/setting/list',
            //             children: [
            //                 {
            //                     path: '/pcms/salary/setting/list',
            //                     name: 'pcms-salary-setting-list',
            //                     meta: { title: '薪资设置' },
            //                     component: () => import('../views/pcms/salary/setting/list.vue')
            //                 },
            //                 {
            //                     path: '/pcms/salary/setting/add',
            //                     name: 'pcms-salary-setting-add',
            //                     meta: { title: '添加薪资设置' },
            //                     component: () => import('../views/pcms/salary/setting/add.vue')
            //                 },
            //                 {
            //                     path: '/pcms/salary/setting/edit',
            //                     name: 'pcms-salary-setting-edit',
            //                     meta: { title: '编辑薪资设置' },
            //                     component: () => import('../views/pcms/salary/setting/add.vue')
            //                 }
            //             ]
            //         },
            //         {
            //             path: '/pcms/salary/statistics',
            //             name: 'pcms-salary-statistics',
            //             component: tempPage,
            //             meta: { title: '薪资统计', isNav: true },
            //             redirect: '/pcms/salary/statistics/list',
            //             children: [
            //                 {
            //                     path: '/pcms/salary/statistics/list',
            //                     name: 'pcms-salary-statistics-list',
            //                     meta: { title: '薪资统计' },
            //                     component: () =>
            //                         import('../views/pcms/salary/statistics/list.vue')
            //                 }
            //             ]
            //         },
            //         {
            //             path: '/pcms/salary/grant',
            //             name: 'pcms-salary-grant',
            //             component: tempPage,
            //             meta: { title: '工资发放', isNav: true },
            //             redirect: '/pcms/salary/grant/list',
            //             children: [
            //                 {
            //                     path: '/pcms/salary/grant/list',
            //                     name: 'pcms-salary-grant-list',
            //                     meta: { title: '工资发放' },
            //                     component: () => import('../views/pcms/salary/grant/list.vue')
            //                 },
            //                 {
            //                     path: '/pcms/salary/grant/details',
            //                     name: 'pcms-salary-grant-details',
            //                     meta: { title: '工资发放详情' },
            //                     component: () =>
            //                         import('../views/pcms/salary/grant/details.vue')
            //                 },
            //                 {
            //                     path: '/pcms/salary/grant/edit',
            //                     name: 'pcms-salary-grant-edit',
            //                     meta: { title: '工资发放编辑' },
            //                     component: () => import('../views/pcms/salary/grant/edit.vue')
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // 车辆管理
            // {
            //     path: '/pcms/car',
            //     name: 'car',
            //     meta: { title: '车辆管理', icon: 'car', isShowChild: true },
            //     component: tempPage,
            //     redirect: '/pcms/car/type',
            //     children: [
            //         {
            //             path: '/pcms/car/type',
            //             name: 'car-type',
            //             meta: { title: '类型管理', isNav: true },
            //             component: () => import('../views/pcms/car/type/list.vue')
            //         },
            //         // 车辆档案
            //         {
            //             path: '/pcms/car/archives',
            //             name: 'car-archives',
            //             meta: { title: '车辆档案', isNav: true },
            //             component: tempPage,
            //             redirect: '/pcms/car/archives/list',
            //             children: [
            //                 {
            //                     path: '/pcms/car/archives/list',
            //                     name: 'car-archives-list',
            //                     meta: { title: '车辆档案' },
            //                     component: () => import('../views/pcms/car/archives/list.vue')
            //                 },
            //                 {
            //                     path: '/pcms/car/archives/add',
            //                     name: 'car-archives-add',
            //                     meta: { title: '添加车辆' },
            //                     component: () => import('../views/pcms/car/archives/add.vue')
            //                 },
            //                 {
            //                     path: '/pcms/car/archives/edit',
            //                     name: 'car-archives-edit',
            //                     meta: { title: '编辑车辆' },
            //                     component: () => import('../views/pcms/car/archives/add.vue')
            //                 },
            //                 {
            //                     path: '/pcms/car/archives/details',
            //                     name: 'car-archives-details',
            //                     meta: { title: '车辆档案详情' },
            //                     component: () =>
            //                         import('../views/pcms/car/archives/details.vue')
            //                 }
            //             ]
            //         },
            //         // 出入口档案
            //         {
            //             path: '/pcms/car/outInArchives',
            //             name: 'car-outInArchives',
            //             meta: { title: '出入口档案', isNav: true },
            //             component: tempPage,
            //             redirect: '/pcms/car/outInArchives/list',
            //             children: [
            //                 // 出入口档案列表
            //                 {
            //                     path: '/pcms/car/outInArchives/list',
            //                     name: 'car-outInArchives-list',
            //                     meta: { title: '出入口档案' },
            //                     component: () =>
            //                         import('../views/pcms/car/outInArchives/list.vue')
            //                 },
            //                 // 出入口档案详情
            //                 {
            //                     path: '/pcms/car/outInArchives/details',
            //                     name: 'car-outInArchives-details',
            //                     meta: { title: '出入口档案详情' },
            //                     component: () =>
            //                         import('../views/pcms/car/outInArchives/details.vue')
            //                 }
            //             ]
            //         },
            //         {
            //             path: '/pcms/car/outInrecords',
            //             name: 'car-outInrecords',
            //             meta: { title: '出入记录', isNav: true },
            //             component: () => import('../views/pcms/car/outInrecords/list.vue')
            //         }
            //     ]
            // },
            // 基础配置
            {
                path: '/pcms/config',
                name: 'config',
                meta: { title: '基础配置', icon: 'setting' },
                component: () => import('../views/pcms/config/index.vue')
            }
        ]
    },

    //机械设备管理系统(mdms)
    {
        path: '/mdms',
        name: 'mdms',
        component: Layout,
        redirect: '/mdms/device',
        children: [
            // 设备管理
            {
                path: '/mdms/device',
                name: 'mdms-device',
                meta: { title: '设备管理', icon: 'database' },
                component: () => import('../views/mdms/device/deviceManger.vue')
            },
            // 设备维保
            {
                path: '/mdms/deviceService',
                name: 'deviceService',
                meta: { title: '设备维保任务', icon: 'insurance' },
                component: () => import('../views/mdms/device/deviceService.vue')
            },
            // 设备档案
            {
                path: '/mdms/deviceFile',
                name: 'deviceFile',
                meta: { title: '设备维保档案', icon: 'insurance' },
                component: () => import('../views/mdms/device/deviceFile.vue')
            },
            // 设备预警
            {
                path: '/mdms/deviceWarn',
                name: 'deviceWarn',
                meta: { title: '设备预警', icon: 'insurance' },
                component: () => import('../views/mdms/device/deviceWarn.vue')
            },
            //出入口管理
            {
                path: '/mdms/deviceEnter',
                name: 'deviceEnter',
                meta: { title: '出入口管理', icon: 'insurance' },
                component: () => import('../views/mdms/device/deviceEnter.vue')
            },
            // 用电监测台账
            {
                path: '/mdms/electricity',
                name: 'electricity',
                meta: { title: '用电监测台账', icon: 'insurance' },
                component: () => import('../views/mdms/device/electricity.vue')
            },
            // 用水监测台账
            {
                path: '/mdms/water',
                name: 'water',
                meta: { title: '用水监测台账', icon: 'insurance' },
                component: () => import('../views/mdms/device/water.vue')
            },
            // 环境监测台账
            {
                path: '/mdms/environment',
                name: 'environment',
                meta: { title: '环境监测台账', icon: 'insurance' },
                component: () => import('../views/mdms/device/environment.vue')
            },
        ]
    },

    //材料管理管理系统（mms）
    {
        path: '/mms',
        name: 'mms',
        component: Layout,
        redirect: '/mms/purchase',
        children: [
            // 采购申请
            {
                path: '/mms/purchase',
                name: 'mms-purchase',
                component: tempPage,
                // component: () => import('../views/mms/purchase/list.vue'),
                meta: { title: '采购申请', icon: 'calendar', isShowChild: false },
                redirect: '/mms/purchase/index',
                children: [
                    {
                        path: '/mms/purchase/index',
                        name: 'mms-purchase/index',
                        meta: { title: '采购清单列表', isNav: true },
                        component: tempPage,
                        redirect: '/mms/purchase/list',
                        children: [
                            {
                                path: '/mms/purchase/list',
                                name: 'mms-purchase-list',
                                meta: { title: '采购清单列表' },
                                component: () => import('../views/mms/purchase/list.vue')
                            },
                            {
                                path: '/mms/purchase/addlist',
                                name: 'mms-purchase-add',
                                meta: { title: '新增采购申请' },
                                component: () => import('../views/mms/purchase/addlist.vue')
                            },
                            {
                                path: '/mms/purchase/addbatch',
                                name: 'mms-purchase-add',
                                meta: { title: '新增批次' },
                                component: () => import('../views/mms/purchase/addbatch.vue')
                            },
                            {
                                path: '/mms/purchase/order',
                                name: 'mms-purchase-details',
                                meta: { title: '查看采购订单' },
                                component: () => import('../views/mms/purchase/order.vue')
                            }
                        ]
                    }
                ]
            },
            //出入库管理
            {
                path: '/mms/inout',
                name: 'mms-inout',
                component: tempPage,
                meta: { title: '出入库管理', icon: 'swap', isShowChild: true },
                redirect: '/mms/inout/stock',
                children: [
                    //库存管理
                    {
                        path: '/mms/inout/stock',
                        name: 'mms-inout-stock',
                        meta: { title: '库存管理', isNav: true },
                        component: tempPage,
                        redirect: '/mms/inout/stock/list',
                        children: [
                            {
                                path: '/mms/inout/stock/list',
                                name: 'mms-inout-stock-list',
                                meta: { title: '库存管理' },
                                component: () => import('../views/mms/inout/stock/list.vue')
                            },
                            {
                                path: '/mms/inout/stock/stockData',
                                name: 'mms-inout-stock-details',
                                meta: { title: '查看材料信息' },
                                component: () =>
                                    import('../views/mms/inout/stock/stockData.vue')
                            }
                        ]
                    },
                    //入库管理
                    {
                        path: '/mms/inout/warehouseIn',
                        name: 'mms-inout-warehouseIn',
                        meta: { title: '入库管理', isNav: true },
                        component: tempPage,
                        redirect: '/mms/inout/warehouseIn/list',
                        children: [
                            {
                                path: '/mms/inout/warehouseIn/list',
                                name: 'mms-inout-warehouseIn-list',
                                meta: { title: '入库管理' },
                                component: () =>
                                    import('../views/mms/inout/warehouseIn/list.vue')
                            },
                            {
                                path: '/mms/inout/warehouseIn/addWareHouseIn',
                                name: 'mms-inout-warehouseIn-add',
                                meta: { title: '新增入库管理' },
                                component: () =>
                                    import('../views/mms/inout/warehouseIn/addWareHouseIn.vue')
                            },
                            {
                                path: '/mms/inout/warehouseIn/warehouseInDetail',
                                name: 'mms-inout-warehouseIn-detail',
                                meta: { title: '查看入库' },
                                component: () =>
                                    import('../views/mms/inout/warehouseIn/warehouseInDetail.vue')
                            }
                        ]
                    },
                    //出库管理
                    {
                        path: '/mms/inout/warehouseOut',
                        name: 'mms-inout-warehouseOut',
                        meta: { title: '出库管理', isNav: true },
                        component: tempPage,
                        redirect: '/mms/inout/warehouseOut/list',
                        children: [
                            {
                                path: '/mms/inout/warehouseOut/list',
                                name: 'mms-inout-warehouseOut-list',
                                meta: { title: '出库管理' },
                                component: () =>
                                    import('../views/mms/inout/warehouseOut/list.vue')
                            },
                            {
                                path: '/mms/inout/warehouseOut/addWarehouseOut',
                                name: 'mms-inout-warehouseOut-add',
                                meta: { title: '新增领取记录' },
                                component: () =>
                                    import('../views/mms/inout/warehouseOut/addWarehouseOut.vue')
                            },
                            {
                                path: '/mms/inout/warehouseOut/warehouseOutDetail',
                                name: 'mms-inout-warehouseOut-detail',
                                meta: { title: '查看出库' },
                                component: () =>
                                    import(
                                        '../views/mms/inout/warehouseOut/warehouseOutDetail.vue'
                                    )
                            }
                        ]
                    }
                ]
            },
            // 材料管理
            {
                path: '/mms/material',
                name: 'mms-material',
                component: tempPage,
                meta: { title: '材料管理', icon: 'appstore', isShowChild: true },
                redirect: '/mms/majorManger',
                children: [
                    {
                        path: '/mms/majorManger',
                        name: 'majorManger',
                        meta: { title: '材料管理列表', icon: 'appstore', isNav: true },
                        component: tempPage,
                        redirect: '/mms/majorMangerList',
                        children: [
                            {
                                path: '/mms/majorMangerList',
                                name: 'mms-major-list',
                                meta: { title: '材料管理列表', icon: 'appstore' },
                                component: () => import('../views/mms/major/list.vue')
                            },
                            {
                                path: '/mms/addMajor',
                                name: 'mms-major-add',
                                meta: { title: '添加材料管理', icon: 'appstore' },
                                component: () => import('../views/mms/major/addMajor.vue')
                            },
                            {
                                path: '/mms/editMajor',
                                name: 'mms-major-edit',
                                meta: { title: '编辑材料管理', icon: 'appstore' },
                                component: () => import('../views/mms/major/addMajor.vue')
                            }
                        ]
                    },
                    {
                        path: '/mms/materialManger',
                        name: 'materialManger',
                        meta: { title: '材料类目', icon: 'appstore', isNav: true },
                        component: tempPage,
                        redirect: '/mms/materialMangerList',
                        children: [
                            {
                                path: '/mms/materialMangerList',
                                name: 'materialMangerList',
                                meta: { title: '材料类目', icon: 'appstore' },
                                component: () => import('../views/mms/material/list.vue')
                            }
                        ]
                    }
                ]
            },
            //仓库管理
            {
                path: '/mms/warehouse',
                name: 'mms-warehouse',
                component: () => import('../views/mms/warehouse/list.vue'),
                meta: { title: '仓库管理', icon: 'appstore' }
            }
        ]
    },

    //法规宣导管理系统(spms)
    {
        path: '/spms',
        name: 'spms',
        component: Layout,
        title: '法规宣导管理系统',
        // redirect: '/spms/Kanban',
        redirect: '/spms/broadcast',
        children: [
            //在线广播管理
            // {
            //     path: '/spms/broadcast',
            //     name: 'spms-broadcast',
            //     meta: { title: '在线广播管理', isNav: true, icon: 'notification' },
            //     component: () => import('../views/spms/broadcast/list.vue')
            // },
            //在线广播管理
            {
                path: '/spms/broadcast',
                name: 'spms-broadcast',
                meta: { title: '在线广播管理', isShowChild: true, icon: 'notification' },
                component: tempPage,
                redirect: '/spms/broadcast/manage',
                children: [

                    {
                        path: '/spms/broadcast/manage',
                        name: '/spms/broadcast/manage',
                        meta: { title: '广播管理', isNav: true, icon: 'laptop' },
                        component: () => import('../views/spms/broadcast/manage.vue'),
                    },
                    {
                        path: '/spms/broadcast/list',
                        name: '/spms/broadcast/list',
                        meta: { title: '在线广播', isNav: true, icon: 'laptop' },
                        // component: () => import('../views/spms/broadcast/list.vue'),
                        component: () => import('../views/spms/broadcast/index.vue'),
                    },
                    // {
                    //     path: '/spms/broadcast/index',
                    //     name: '/spms/broadcast/index',
                    //     meta: { title: '在线广播测试', isNav: true, icon: 'laptop' },
                    //     component: () => import('../views/spms/broadcast/index.vue'),
                    // },
                ]
            },

            {
                path: '/spms/Kanban/',
                name: 'spms-Kanban',
                meta: { title: '看板内容管理', isNav: true, icon: 'laptop' },
                component: tempPage,
                redirect: '/spms/Kanban/list',
                children: [
                    {
                        path: '/spms/Kanban/list',
                        name: '/spms/Kanban/list',
                        meta: { title: '看板内容', isNav: true, icon: 'laptop' },
                        component: () => import('../views/spms/Kanban/list.vue')
                    },
                    {
                        path: '/spms/Kanban/add',
                        name: '/spms/Kanban/add',
                        meta: { title: '新增看板内容' },
                        component: () => import('../views/spms/Kanban/add.vue')
                    },
                    {
                        path: '/spms/Kanban/plan',
                        name: '/spms/Kanban/plan',
                        meta: { title: '投放管理' },
                        component: () => import('../views/spms/Kanban/plan.vue')
                    }
                ]
            },
            {
                path: '/spms/launchPlan',
                name: 'spms-launchPlan',
                meta: { title: '投放计划', isNav: true, icon: 'calendar' },
                component: tempPage,
                redirect: '/spms/launchPlan/add',
                children: [
                    {
                        path: '/spms/launchPlan/list',
                        name: '/spms/launchPlan/list',
                        meta: { title: '投放管理', isNav: true, icon: 'calendar' },
                        component: () => import('../views/spms/launchPlan/list.vue')
                    },
                    {
                        path: '/spms/launchPlan/add',
                        name: '/spms/launchPlan/add',
                        meta: { title: '投放计划' },
                        component: () => import('../views/spms/Kanban/plan.vue')
                    },
                    {
                        path: '/spms/launchPlan/detail',
                        name: '/spms/launchPlan/detail',
                        meta: { title: '投放详情' },
                        component: () => import('../views/spms/launchPlan/detail.vue')
                    }
                ]
            },
            //基础配置
            {
                path: '/spms/baseConfig',
                name: 'spms-baseConfig',
                meta: { title: '基础配置', isShowChild: true, icon: 'notification' },
                component: tempPage,
                redirect: '/spms/baseConfig/index',
                children: [
                    {
                        path: '/spms/baseConfig/index',
                        name: 'spms-baseConfig-index',
                        meta: { title: '基础配置', isNav: true, icon: 'notification' },
                        component: () => import('../views/spms/baseConfig/index.vue')
                    }
                ]
            },
        ]
    },

    //质量监督管理系统(qsms)
    {
        path: '/qsms',
        name: 'qsms',
        component: Layout,
        redirect: '/qsms/inspection',
        children: [
            {
                path: '/qsms/inspection',
                name: 'qsms-inspection',
                meta: { title: '质量巡检', icon: 'file-search' },
                component: tempPage,
                redirect: '/qsms/inspection/record',
                children: [
                    {
                        path: '/qsms/inspection/record',
                        name: 'qsms-inspection-record',
                        component: tempPage,
                        meta: { title: '质量巡检记录', isNav: true },
                        redirect: '/qsms/inspection/record/list',
                        children: [
                            {
                                path: '/qsms/inspection/record/list',
                                name: 'qsms-inspection-record-list',
                                meta: { title: '质量巡检记录' },
                                component: () =>
                                    import('../views/qsms/inspection/record/list.vue')
                            },
                            {
                                path: '/qsms/inspection/record/add',
                                name: 'qsms-inspection-record-add',
                                meta: { title: '新增质量巡检记录' },
                                component: () =>
                                    import('../views/qsms/inspection/record/add.vue')
                            },
                            {
                                path: '/qsms/inspection/record/edit',
                                name: 'qsms-inspection-record-edit',
                                meta: { title: '编辑质量巡检记录' },
                                component: () =>
                                    import('../views/qsms/inspection/record/add.vue')
                            },
                            {
                                path: '/qsms/inspection/record/details',
                                name: 'qsms-inspection-record-details',
                                meta: { title: '查看质量巡检记录' },
                                component: () =>
                                    import('../views/qsms/inspection/record/details.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: '/qsms/config',
                name: 'qsms-config',
                meta: { title: '基础配置', isNav: true, icon: 'setting' },
                component: () => import('../views/qsms/config/list.vue')
            }
        ]
    },

    //安全监督管理系统(ssms)
    {
        path: '/ssms',
        name: 'ssms',
        component: Layout,
        redirect: '/ssms/inspection',
        children: [
            //安全巡检
            {
                path: '/ssms/inspection',
                name: 'ssms-inspection',
                component: tempPage,
                meta: { title: '安全巡检', icon: 'file-search' },
                redirect: '/ssms/inspection/record',
                children: [
                    {
                        path: '/ssms/inspection/record',
                        name: 'ssms-inspection-record',
                        component: tempPage,
                        meta: { title: '安全巡检记录', isNav: true },
                        redirect: '/ssms/inspection/record/list',
                        children: [
                            {
                                path: '/ssms/inspection/record/list',
                                name: 'ssms-inspection-record-list',
                                meta: { title: '安全巡检记录' },
                                component: () =>
                                    import('../views/ssms/inspection/record/list.vue')
                            },
                            {
                                path: '/ssms/inspection/record/add',
                                name: 'ssms-inspection-record-add',
                                meta: { title: '添加安全巡检' },
                                component: () =>
                                    import('../views/ssms/inspection/record/add.vue')
                            },
                            {
                                path: '/ssms/inspection/record/edit',
                                name: 'ssms-inspection-record-edit',
                                meta: { title: '编辑安全巡检' },
                                component: () =>
                                    import('../views/ssms/inspection/record/add.vue')
                            },
                            {
                                path: '/ssms/inspection/record/details',
                                name: 'ssms-inspection-record-details',
                                meta: { title: '安全巡检详情' },
                                component: () =>
                                    import('../views/ssms/inspection/record/details.vue')
                            }
                        ]
                    }
                ]
            },
            //安全作业
            {
                path: '/ssms/task',
                name: 'ssms-task',
                component: tempPage,
                meta: { title: '安全作业', icon: 'profile' },
                redirect: '/ssms/task/record',
                children: [
                    {
                        path: '/ssms/task/record',
                        name: 'ssms-task-record',
                        component: tempPage,
                        meta: { title: '安全作业记录', isNav: true },
                        redirect: '/ssms/task/record/list',
                        children: [
                            {
                                path: '/ssms/task/record/list',
                                name: 'ssms-task-record-list',
                                meta: { title: '安全作业记录' },
                                component: () => import('../views/ssms/task/record/list.vue')
                            },
                            {
                                path: '/ssms/task/record/add',
                                name: 'ssms-task-record-add',
                                meta: { title: '添加安全作业' },
                                component: () => import('../views/ssms/task/record/add.vue')
                            },
                            {
                                path: '/ssms/task/record/edit',
                                name: 'ssms-task-record-edit',
                                meta: { title: '编辑安全作业' },
                                component: () => import('../views/ssms/task/record/add.vue')
                            },
                            {
                                path: '/ssms/task/record/details',
                                name: 'ssms-task-record-details',
                                meta: { title: '安全作业详情' },
                                component: () => import('../views/ssms/task/record/details.vue')
                            }
                        ]
                    }
                ]
            },
            //安全教育
            {
                path: '/ssms/education',
                name: 'ssms-education',
                component: tempPage,
                meta: { title: '安全教育', icon: 'audit' },
                redirect: '/ssms/education/train',
                children: [
                    {
                        path: '/ssms/education/train',
                        name: 'ssms-education-train',
                        component: tempPage,
                        meta: { title: '安全培训', isNav: true },
                        redirect: '/ssms/education/train/list',
                        children: [
                            {
                                path: '/ssms/education/train/list',
                                name: 'ssms-education-train-list',
                                meta: { title: '安全培训' },
                                component: () =>
                                    import('../views/ssms/education/train/list.vue')
                            },
                            {
                                path: '/ssms/education/train/add',
                                name: 'ssms-education-train-add',
                                meta: { title: '添加安全培训' },
                                component: () => import('../views/ssms/education/train/add.vue')
                            },
                            {
                                path: '/ssms/education/train/edit',
                                name: 'ssms-education-train-edit',
                                meta: { title: '编辑安全培训' },
                                component: () => import('../views/ssms/education/train/add.vue')
                            },
                            {
                                path: '/ssms/education/train/details',
                                name: 'ssms-education-train-details',
                                meta: { title: '安全培训详情' },
                                component: () =>
                                    import('../views/ssms/education/train/details.vue')
                            }
                        ]
                    }
                ]
            },
            //安全基础配置
            {
                path: '/ssms/conifg',
                name: 'ssms-conifg',
                meta: { title: '基础配置', icon: 'setting' },
                component: () => import('../views/ssms/conifg/index.vue')
            }
        ]
    },

    //项目信息管理系统(pims)
    {
        path: '/pims',
        name: 'pims',
        component: Layout,
        redirect: '/pims/projectInfo',
        children: [
            // 项目管理
            {
                path: '/pims/projectInfo',
                name: 'pims-project-info',
                meta: { title: '项目管理', isNav: true, icon: 'project' },
                component: tempPage,
                redirect: '/pims/projectPlan/info',
                children: [
                    {
                        path: '/pims/projectPlan/info',
                        name: 'pims-projectPlan-info',
                        meta: { title: '项目信息', isNav: true },
                        component: tempPage,
                        redirect: '/pims/projectPlan/info/details',
                        children: [
                            {
                                path: '/pims/projectPlan/info/details',
                                name: 'pims-projectPlan-info-detail',
                                meta: { title: '项目信息' },
                                component: () => import('../views/pims/projectInfo/index.vue')
                            },
                            {
                                path: '/pims/projectPlan/info/edit',
                                name: 'pims-projectPlan-info-edit',
                                meta: { title: '项目编辑' },
                                component: () =>
                                    import('../views/pims/projectInfo/addProject.vue')
                            },
                            {
                                path: '/pims/projectPlan/info/add',
                                name: 'pims-projectPlan-info-add',
                                meta: { title: '新增项目' },
                                component: () =>
                                    import('../views/pims/projectInfo/addProject.vue')
                            },
                            {
                                path: '/pims/projectPlan/info/addRoles',
                                name: 'pims-projectPlan-info-addRoles',
                                meta: { title: '新增角色' },
                                component: () =>
                                    import('../views/pims/projectInfo/addRoles.vue')
                            },
                            {
                                path: '/pims/projectPlan/info/editRoles',
                                name: 'pims-projectPlan-info-editRoles',
                                meta: { title: '编辑角色' },
                                component: () =>
                                    import('../views/pims/projectInfo/addRoles.vue')
                            }
                        ]
                    }
                ]
            },
            // {
            //   path: '/pims/projectEdit',
            //   name: 'project-edit',
            //   meta: { title: '项目编辑'},
            //   component: () => import('../views/pims/projectInfo/addProject.vue')
            // },
            // 计划进度
            {
                path: '/pims/projectPlan',
                name: 'project-plan',
                meta: {
                    title: '计划进度',
                    isNav: true,
                    icon: 'profile',
                    isShowChild: true
                },
                component: tempPage,
                redirect: '/pims/projectPlan/milepost',
                children: [
                    // 里程牌配置
                    {
                        path: '/pims/projectPlan/milepost',
                        name: 'projectPlan-milepost',
                        meta: { title: '里程牌管理', isNav: true },
                        component: tempPage,
                        redirect: '/pims/projectPlan/milepost/list',
                        children: [
                            {
                                path: '/pims/projectPlan/milepost/list',
                                name: 'projectPlan-milepost-list',
                                meta: { title: '里程牌管理' },
                                component: () =>
                                    import('../views/pims/projectPlan/milepost/index.vue')
                            },
                            {
                                path: '/pims/projectPlan/milepost/config',
                                name: 'projectPlan-milepost-config-edit',
                                meta: { title: '里程牌配置' },
                                component: () =>
                                    import('../views/pims/projectPlan/milepost/config.vue')
                            },
                            {
                                path: '/pims/projectPlan/milepost/progress',
                                name: 'projectPlan-milepost-progress-edit',
                                meta: { title: '进度管理' },
                                component: () =>
                                    import('../views/pims/projectPlan/milepost/progress.vue')
                            }
                        ]
                    },
                    // 进度验收
                    {
                        path: '/pims/projectPlan/acceptance',
                        name: 'projectPlan-acceptance',
                        meta: { title: '进度验收', isNav: true },
                        component: () =>
                            import('../views/pims/projectPlan/acceptance/index.vue')
                    }
                ]
            }
        ]
    },

    // 环境统计大盘(environmental)
    {
        path: '/environmental',
        name: 'environmental',
        component: mainHeader,
        redirect: '/environmental/index',
        children: [
            {
                path: '/environmental/index',
                name: 'environmental-index',
                component: () => import('../views/bigScreen/environmental.vue')
            }
        ]
    },

    // 塔吊系统(power)
    {
        path: '/tower',
        name: 'tower',
        component: mainHeader,
        redirect: '/tower/index',
        children: [
            {
                path: '/tower/index',
                name: 'tower-index',
                component: () => import('../views/tower.vue')
            }
        ]
    },

    // 出入口(enter)
    {
        path: '/enter',
        name: 'enter',
        component: mainHeader,
        redirect: '/enter/index',
        children: [
            {
                path: '/enter/index',
                name: 'enter-index',
                component: () => import('../views/enter.vue')
            }
        ]
    },

    // 物联统计
    {
        path: '/webThing',
        name: 'webThing',
        component: mainHeader,
        redirect: '/webThing/index',
        children: [
            {
                path: '/webThing/index',
                name: 'webThing-index',
                component: () => import('../views/webThing.vue')
            }
        ]
    },

    // 首页大屏(monitor)
    {
        path: '/monitor',
        name: 'monitor',
        component: mainHeader2,
        redirect: '/monitor/index',
        children: [
            {
                path: '/monitor/index',
                name: 'monitor-index',
                component: () => import('../views/monitor_screen/home.vue')
            }
        ]
    },

    // 会展大屏
    {
        path: '/tvScreen',
        name: 'tvScreen',
        component: mainHeader,
        redirect: '/tvScreen/index',
        children: [
            {
                path: '/tvScreen/index',
                name: 'tvScreen-index',
                component: () => import('../views/tvScreen/home.vue')
            }
        ]
    },

    // 大数据应用平台(bigdata)
    {
        path: '/bigdata',
        name: 'bigdata',
        component: Layout,
        redirect: '/bigdata/alarm-type',
        children: [
            {
                path: 'alarm-type',
                name: 'AlarmType',
                meta: { title: '预警类型', icon: 'alert' },
                component: () => import('../views/bigdata/AlarmType')
            },
            {
                path: 'alarm-level',
                name: 'AlarmLevel',
                meta: { title: '预警等级', icon: 'alert' },
                component: () => import('../views/bigdata/AlarmLevel')
            },
            {
                path: 'alarm-rule',
                name: 'AlarmRule',
                meta: { title: '预警规则', icon: 'alert' },
                component: () => import('../views/bigdata/AlarmRule')
            },
            {
                path: '/bigdata/alarm-rule-detail',
                name: 'AlarmRuleDetail',
                meta: { title: '预警规则库', icon: 'alert' },
                component: () => import('../views/bigdata/AlarmRuleDetail')
            },
            {
                path: 'push-rule',
                name: 'PushRule',
                meta: { title: '推送规则', icon: 'alert' },
                component: () => import('../views/bigdata/PushRule')
            },
            {
                path: 'alarm-event',
                name: 'AlarmEvent',
                meta: { title: '预警中心', icon: 'alert' },
                component: () => import('../views/bigdata/AlarmEvent')
            }
        ]
    },


    // 综合业务预警
    {
        path: '/collision-alarm',
        name: 'collision-alarm',
        component: Layout,
        title: '综合业务预警',
        redirect: '/collision-alarm/alarm-event',
        children: [
            // 设备管理
            {
                path: '/collision-alarm/alarm-event',
                name: 'collision-alarm-AlarmEvent',
                meta: { title: '预警事件', icon: 'database' },
                component: () => import('@/views/collision-alarm/alarm-event/Index')
            },
            {
                path: '/collision-alarm/alarm-rule',
                name: 'collision-alarm-AlarmRule',
                meta: { title: '预警规则', icon: 'database' },
                component: () => import('@/views/collision-alarm/alarm-rule/Index')
            },
            // // 预警规则库
            {
                path: '/collision-alarm/alarm-rule-base',
                name: 'collision-alarm-AlarmRuleBase',
                meta: { title: '预警规则库', icon: 'database', },
                component: () => import('@/views/collision-alarm/alarm-rule/AlarmRuleBase')
            },
            // {
            //     path: '/collision-alarm/push-rule',
            //     name: 'collision-alarm-PushRule',
            //     meta: { title: '推送规则', icon: 'database' },
            //     component: () => import('@/views/collision-alarm/push-rule/Index')
            // },
            // {
            //     path: '/collision-alarm/state-grade',
            //     name: 'collision-alarm-StateGrade',
            //     meta: { title: '事态等级', icon: 'database' },
            //     component: () => import('@/views/collision-alarm/state-grade/Index')
            // },
            {
                path: '/collision-alarm/alarm-type',
                name: 'collision-alarm-AlarmType',
                meta: { title: '预警类型', icon: 'database' },
                component: () => import('@/views/collision-alarm/alarm-type/Index')
            },
            {
                path: '/collision-alarm/alarm-grade',
                name: 'collision-alarm-AlarmGrade',
                meta: { title: '预警等级', icon: 'database' },
                component: () => import('@/views/collision-alarm/alarm-grade/Index')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: window.__POWERED_BY_QIANKUN__
        ? '/wisdom-building/work-site/'
        : '/work-site/',
    routes
})

router.afterEach(() => {
    window.vueInstance.$store.commit('SET_SUBMITLOADDING', false)
})

export default router
