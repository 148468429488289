import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'
// import baseURL from './env'
// import store from '../store/index'
// import Router from '../router/index'

const key = 'messageKey'
const duration = 2
//环境切换
// axios.defaults.baseURL = baseURL
axios.defaults.baseURL = '/api/work-site';
//请求超时时间
axios.defaults.timeout = 0

//在传递给 then/catch 前，统一修改响应数据
axios.defaults.transformResponse = [
    function (data) {
        try {
            return JSON.parse(data)
        } catch (e) {
            return data
        }
    }
]

//请求拦截器
axios.interceptors.request.use(
    (config) => {
        // var istoken = config.url
        // if(istoken){
        //     config.headers['Authorization'] = istoken
        // }else{
        if (config.url.startsWith('/v1')) {
            let access_token = localStorage.getItem("access_token")
            config.headers['Authorization'] = "bearer " + access_token
        } else {
            config.headers['Authorization'] = window.vueInstance.$store.state.authorization
        }

        // }

        // let filterUrl = [
        //     '/publicize/audio/save',//广播-上传音频
        // ]
        // let currentUrl = config.url.split('?')[0]
        // // console.log('currentUrl', currentUrl);
        // if (filterUrl.includes(currentUrl)) {
        //     config.baseURL = '/api'
        // }

        return config
    },
    (error) => {
        return Promise.error(error)
    }
)

//响应拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            // let _data = response.data || {}

            //用户提交表单或按钮时，当请求接口返回异常时，错误提示
            let method = response.config.method
            if (method === 'post' || method === 'put' || method === 'delete') {
                window.vueInstance.$store.commit('SET_SUBMITLOADDING', false)
                // let status = _data.code === 200 && _data.data
                // window.vueInstance.$message[status ? 'success' : 'error']({
                //     content: _data.msg || (status ? '操作成功' : '操作失败'),
                //     key,
                //     duration
                // })
            }

            return Promise.resolve(response.data)
        } else {
            window.vueInstance.$store.commit('SET_SUBMITLOADDING', false)
            return Promise.reject(response.data)
        }
    },
    (error) => {
        setTimeout(() => {
            window.vueInstance.$store.commit('SET_SUBMITLOADDING', false)
        }, 5000)
        if (error && error.response && error.response.status) {
            window.vueInstance.$store.commit('SET_SUBMITLOADDING', false)
            //console.log(error.response)

            switch (error.response.status) {
                case 401:
                    //未登录
                    var data = error.response.data
                    var content =
                        data.code === 301
                            ? '登录认证过期，请重新登录'
                            : '登录认证失败，请重新登录'
                    window.vueInstance.$message.error({
                        content,
                        key,
                        duration
                    })
                    window.vueInstance.$router.push({ path: '/login' })
                    break
                case 404:
                    new Vue().$message.error({
                        content: `请求不存在(${error.response.status})`,
                        key,
                        duration
                    })
                    //请求不存在
                    break
                case 500:
                    window.vueInstance.$message.error({
                        content: `服务器异常(${error.response.status})`,
                        key,
                        duration
                    })
                    break
                case 503:
                    window.vueInstance.$message.error({
                        content: `服务器异常(${error.response.status})`,
                        key,
                        duration
                    })
                    break

                default:
                //其他异常
            }
            return Promise.reject(error.response)
        } else {
            return Promise.reject(error)
        }
    }
)

export default {
    //get
    get: function (url, params) {
        return axios({
            method: 'get',
            url: url,
            params: params
        })
    },
    // get
    getParams: function (url, data) {
        return axios({
            method: 'get',
            url: url + `/${data}`
        })
    },
    //post  application/x-www-form-urlencoded
    post(url, data) {
        return axios({
            method: 'post',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: url,
            data: qs.stringify(data)
        })
    },
    //post  application/json
    postJson(url, data) {
        return axios({
            method: 'post',
            headers: { 'content-type': 'application/json' },
            url: url,
            data: data
        })
    },
    //post  multipart/form-data
    postForm(url, data) {
        return axios({
            method: 'post',
            url: url,
            data: data,
            timeout: 0
        })
    },
    postTransJson(url, data) {
        return axios({
            method: 'post',
            headers: { 'content-type': 'application/json' },
            url: url,
            data: JSON.stringify(data)
        })
    },
    //delete
    delete(url, data) {
        return axios({
            method: 'delete',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: url,
            data: qs.stringify(data)
        })
    },
    deleteParams(url, data) {
        return axios({
            method: 'delete',
            url: url + `/${data}`
        })
    },
    //put
    put(url, data) {
        return axios({
            method: 'put',
            url: url,
            data: data
        })
    },
    putParams(url, data) {
        return axios({
            method: 'put',
            url: url + `/${data}`
        })
    },
    //put  application/json
    putJson(url, data) {
        return axios({
            method: 'put',
            headers: { 'content-type': 'application/json' },
            url: url,
            data: JSON.stringify(data)
        })
    },
    //delete json格式
    deleteJson(url, data) {
        return axios({
            method: 'delete',
            headers: { 'content-type': 'application/json' },
            url: url,
            data: JSON.stringify(data)
        })
    },
}
