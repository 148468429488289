/*
    content:全局公用接口
    time:2020-10-12

*/ 


import http from '../http'


export default {
    login: p => http.post('/cloud-auth/auth/form', p), //登录
    logout: p => http.delete('/cloud-auth/token/logout', p), //退出登录

    queryCompanyDropDownBox: p => http.get('/project/company/getCompanyDropDownBox', p), //单位下拉框
    queryTypeDropDownBox: p => http.get('/project/companyType/getTypeDropDownBox', p), //单位类型下拉框
    queryTeamDropDownBox: p => http.get('/project/team/getTeamDropDownBox', p), //班组下拉框
    queryJobsDropDownBox: p => http.get('/project/jobs/getJobsDropDownBox', p), //工种/职务下拉框(type:1=工种 2=职务)
    queryUserDropDownBox: p => http.get('/project/user/userDropDownBox', p), //账号用户下拉框
    queryPerDropDownBox: p => http.get('/personnel/personnel/getPerDropDownBox', p), //人员选项框(公司-人员树形结构)
    queryPersonnelOptionsList: p => http.get('/personnel/personnel/selPersonnelOptionsList', p), //人员下拉列表
    queryUserOptionsDialog: p => http.get('/project/user/getUserOptionsDialog', p), //账号选项框
    queryGateMachineList: p => http.get('/device/device/getGateMachine', p), //人脸设备选项框

    personnelCity: p => http.get('/personnel/personnelCity/list', p), //省市区


    //文件上传
    queryPicServer: p => http.get('/personnel/upload/picServer.do', p), //阿里云图片地址
    uploadFile: p => http.postJson('/personnel/upload/uploadFile.do', p), //文件上传

    seedNoticeInfo: p => http.get('/data/led/seedNoticeInfo?resourceId='+p), 
    getCameraList: p => http.get('/device/device/cameraList', p), //监控列表
    customizationStart: p => http.get('/device/monitoring/hik/cloud/customization/start', p), //海康云眸 - 开始控制
    customizationStop: p => http.get('/device/monitoring/hik/cloud/customization/stop', p), //海康云眸 - 停止控制
    customizationCapture: p => http.get('/device/monitoring/hik/cloud/customization/capture', p), //海康云眸 - 抓图

}


