<script>
// import WorksiteApi from "@/api/WorksiteApi";
import FullScreenUtil from "@/utils/FullScreenUtil";
import HeaderDecoration from "@/components/HeaderDecoration";
import moment from "moment";

export default {
  name: "AppHeader",
  components: {
    HeaderDecoration,
  },
  data() {
    return {
      isFullScreen: false,
      objScreen: null,
      projectName: "基筑工地",

      timer: "",
      currentTime: "",
      currentDate: "",
      currentWeek: "",
      fullScreen: false,
      weather: "晴转多云",
      temperature: "18℃/25℃",
      temp_low: "18",
      temp_high: "25",
    };
  },
  created() {
    this.getCurrentDate();

    this.getDeviceWeatherData(); //获取当天天气
    this.timer = setInterval(() => {
      this.getCurrentDate();
    }, 1000);
  },
  mounted() {
    this.objScreen = new FullScreenUtil(this.screenChange);
    this.getProject();
  },
  destroyed() {
    this.objScreen.destroy();
  },
  methods: {
    getCurrentDate() {
      this.currentTime = moment().format("HH:mm");
      this.currentDate = moment().format("YYYY/MM/DD");
      this.currentWeek = "星期" + "日一二三四五六".charAt(new Date().getDay());
    },
    //天气
    getDeviceWeatherData() {
      this.$api.getDeviceWeatherData().then((res) => {
        if (res.code == 200) {
          this.weather =
            res.data.weather && res.data.weather != ""
              ? res.data.weather
              : "晴转多云";
          this.temperature =
            res.data.temperature && res.data.temperature != ""
              ? res.data.temperature
              : "18℃/25℃";
          this.temp_low =
            res.data.temp_low && res.data.temp_low != ""
              ? res.data.temp_low
              : "18";
          this.temp_high =
            res.data.temp_high && res.data.temp_high != ""
              ? res.data.temp_high
              : "25";
        }
      });
    },
    screenChange(isFullScreen) {
      this.isFullScreen = isFullScreen;
    },
    screenHandle() {
      this.objScreen && this.objScreen.toggleFullScreen();
    },
    goBack() {
      location.href = window.location.origin + "/smartsite/workbench/index";
      // location.href = 'https://pas.tsingning.com/wisdomBuilding'
    },
    getProject() {
      //   WorksiteApi.getProject().then((res) => {
      //     if (res.code == 200) {
      //       console.log(res.data.projectName);
      //       this.projectName = res.data.projectName;
      //     }
      //   });
    },
    clickUrl(url) {
      location.href = window.location.origin + url;
    },
  },
};
</script>

<template>
  <div class="app-header">
    <HeaderDecoration title="会展西路过江隧道工程营区信息化管理云平台" />
    <div class="header-left">
      <!-- <div class="logo">
        <img src="../../assets/img/header_logo.png" alt="" />
      </div> -->

      <div class="tianqia">
        <a-row class="item wrap-time">
          <span>{{ currentTime }}</span>
        </a-row>
        <a-row class="item wrap-date">
          <p class="week">{{ currentWeek }}</p>
          <p>{{ currentDate }}</p>
        </a-row>
      </div>

      <!-- <a class="platform">
        <img src="../../assets/img/header_worksite.png" alt="" />
        <router-link class="title" :to="{ name: 'CompanyBigscreen' }">
          {{ projectName }}
        </router-link>
        <i class="ali-icon ali-icon-arrow-down"></i>
      </a>
      <a class="btn-back" @click="goBack">
        <i class="ali-icon ali-icon-nav-home"></i>
      </a> -->
    </div>
    <div class="header-right">
      <!-- :to="{ name: 'CompanyBigscreen' }" -->
      <!-- <router-link class="toolbar-item" >
				<img src="../../assets/img/header_toolbar.png" alt="" />
				<span class="title">
					可视化中心
				</span>
			</router-link> -->

      <a-row class="item wrap-weather">
        <p class="weather">{{ weather }}</p>
        <p>{{ temp_low }}℃~{{ temp_high }}℃</p>
      </a-row>

      <!-- <a-popover
        placement="bottom"
        overlayClassName="tooltip-popover"
        trigger="click"
      >
        <a class="toolbar-item">
          <img src="../../assets/img/header_toolbar.png" alt="" />
          <span class="title"> 数字工地 </span>
        </a>
        <div class="bigscreen-list" slot="content">
          <router-link class="list-item" :to="{ name: 'WorksiteBigscreen' }"
            >数字工地</router-link
          >
          <router-link class="list-item" :to="{ name: 'VideoMonitor' }"
            >监控中心</router-link
          >
          <router-link class="list-item" :to="{ name: 'TowerCrane' }"
            >塔吊监测</router-link
          >
          <router-link class="list-item" :to="{ name: 'EntranceExit' }"
            >出入口监测</router-link
          >
        </div>
      </a-popover> -->
      <a class="toolbar-item" @click="clickUrl('/work-site/home')">
        <img src="../../assets/img/header_toolbar.png" alt="" />
        <span class="title"> 工作台 </span>
      </a>

      <!-- <a class="toolbar-item" @click="clickUrl('/smartsite/worksite/user/account')">
				<img src="../../assets/img/header_toolbar.png" alt="" />
				<span class="title">
					个人中心
				</span>
			</a> -->
      <a class="toolbar-screen" @click="screenHandle">
        <!-- <i
          :class="`ali-icon ali-icon-${
            isFullScreen ? 'exit-fullscreen' : 'fullscreen'
          }`"
        ></i> -->

        <img
              v-if="!isFullScreen"
              style="width: 20px; height: 20px"
              src="../../assets/img/zankai-icon.png"
              alt
            />
            <img
              v-else
              style="width: 20px; height: 20px"
              src="../../assets/img/suoxiao-icon.png"
              alt
            />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes fromTopAnimation {
  0% {
    transform: translateY(-90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.app-header {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
//   animation: fromTopAnimation 1.5s;
  .header-left {
    position: absolute;
    top: 0;
    left: 16px;
    height: 90px;
    display: flex;
    align-items: center;
    .logo {
      text-align: center;
      width: 102px;
      background-image: url("../../assets/img/header_base_left.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .platform {
      width: 133px;
      height: 52px;
      padding: 10px 12px 0 12px;
      margin-left: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/img/header_base_left.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      cursor: pointer;
      .title {
        max-width: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        margin: 0 4px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #ffffff 15%,
          #77c3ff 79%
        );
        background-clip: text;
        color: transparent;
        font-family: biaotihei;
        font-size: 18px;
        text-shadow: 0px 2px 4px 0px rgba(178, 232, 255, 0.58);
      }
      .ali-icon-arrow-down {
        font-size: 12px;
        width: 12px;
        height: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.75);
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #a8d9ff 15%,
          #279ffe 79%
        );
        background-clip: text;
        color: transparent;
        text-shadow: 0px 2px 4px rgba(178, 232, 255, 0.58);
      }
    }
    .btn-back {
      width: 50px;
      height: 52px;
      margin-left: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/img/screen_base.png");
      background-repeat: no-repeat;
      background-position: 0 23px;
      padding: 10px 0 0 0;
      font-weight: bold;
      .ali-icon {
        font-size: 18px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #a8d9ff 15%,
          #279ffe 79%
        );
        background-clip: text;
        color: transparent;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  .header-right {
    position: absolute;
    top: 0;
    right: 16px;
    height: 90px;
    display: flex;
    align-items: center;
    .toolbar-item {
      cursor: pointer;
      width: 134px;
      height: 52px;
      padding: 12px 0 0 8px;
      margin-right: 16px;
      background-image: url("../../assets/img/header_base_right.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      &:hover {
        .title {
          color: #ffffff;
          background: transparent;
        }
      }
      .title {
        cursor: pointer;
        display: inline-flex;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #a8d9ff 15%,
          #279ffe 79%
        );
        background-clip: text;
        color: transparent;
        font-family: biaotihei;
        font-size: 16px;
        text-shadow: 0px 2px 4px 0px #004157;
      }
    }
    .toolbar-screen {
      width: 50px;
      height: 52px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/img/screen_base.png");
      background-repeat: no-repeat;
      background-position: 0 23px;
      padding: 10px 0 0 0;
      .ali-icon {
        font-size: 18px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #a8d9ff 15%,
          #279ffe 79%
        );
        background-clip: text;
        color: transparent;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}

.bigscreen-list {
  width: 120px;
  .list-item {
    display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    &:hover {
      cursor: pointer;
      border-radius: 2px;
      background: #0083df;
    }
  }
}
.tianqia {
  margin-left: 60px;
  margin-top: 14px;
  display: flex;
}
.wrap-time {
  span {
    font-size: 24px;
    font-family: digital;
  }
}
.wrap-date {
  text-align: center;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(148, 148, 148, 0.5);
  padding-right: 24px;
  p {
    font-family: digital;
    margin: 0;
  }
  .week {
    font-family: digital;
    font-size: 14px;
    letter-spacing: 2px;
  }
}
.wrap-weather {
    margin-top: 14px;
    padding-right: 20px;
    p {
    margin: 0;
      font-family: digital;
    }
    .weather {
      font-size: 14px;
      letter-spacing: 2px;
      font-family: digital;
      font-family: STYuanti-TC-Regular, STYuanti-TC;
    }
  }
</style>
