import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'
import createPersistedState from 'vuex-persistedstate' //存储插件

Vue.use(Vuex)

const state = {
  authorization: '', //用户登录校验
  picServer: '', //图片访问服务器
  userInfo: {
    userId: '',
    companyId: '',
    userNickname: '',
    phone: ''
  },
  submitLoadding: false, //提交按钮状态
  pType: 1
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {},
  plugins: [
    createPersistedState({
      key: 'SmartBuilding_State'
    })
  ]
})
