const mutations = {
	['INITIAL_STATE'](state) {
		state.authorization = ''
		state.userInfo.userId = ''
		state.userInfo.userNickname = ''
		state.userInfo.companyId = ''
		state.userInfo.phone = ''
	},
	['SET_AUTHORIZATION'](state, authorization) {
		state.authorization = authorization
	},
	['SET_USERINFO'](state, data) {
		state.userInfo.userId = data.userId
	},
	['SET_USERNICKNAME'](state, userNickname) {
		state.userInfo.userNickname = userNickname
	},
	['SET_COMPANYID'](state, companyId) {
		state.userInfo.companyId = companyId
	},
	['SET_PICSERVER'](state, picServer) {
		state.picServer = picServer
	},
	['SET_PHONE'](state, phone) {
		state.userInfo.phone = phone
	},
	['SET_SUBMITLOADDING'](state, status) {
		state.submitLoadding = status
	},
	['SET_PTYPE'](state, type) {
		state.pType = type
	}
}

export default mutations
