/*
    content:法规宣导管理系统接口列表
    time:2020-10-12

*/


import http from '../http'

//在线广播
const broadcastMngApi = {
    queryBroadcastDeviceState: p => http.get('/publicize/contentSet/broadcastDeviceState', p), //音频-广播设备状态
    queryResourceList: p => http.get('/publicize/contentSet/resourcePage', p), //音频-列表
    deleteResource: p => http.delete('/publicize/contentSet/delResource', p), //音频-删除
    // insertResource: p => http.postJson('/publicize/contentSet/addResource', p), //音频-新增
    insertResource: p => http.postJson('/publicize/audio/save', p), //音频-新增
    playAudioFiles: p => http.get('/publicize/contentSet/playAudioFiles', p), //播放文件广播
    queryCurPlayStatus: p => http.get('/publicize/contentSet/curPlayStatus', p), //当前广播播放状态
    stopAudioBroadcast: p => http.get('/publicize/contentSet/stopAudioBroadcast', p), //停止播放广播

    getBroadcastPage: p => http.get('/publicize/broadcast/page', p),//广播管理分页查询广播设备
    addBroadcast: p => http.postJson('/publicize/broadcast/save', p),//新增广播设备
    deleteBroadcast: p => http.deleteJson('/publicize/broadcast/remove', p),//删除广播设备
    updateBroadcast: p => http.put('/publicize/broadcast/update', p),//更新广播设备
    getUsableAreaList: p => http.get('/publicize/broadcast/usableAreaList', p),//查询区域
    getNoTaskDeviceList: p => http.get('/publicize/broadcast/noTaskDeviceList', p),//查询设备
    // getBroadcastByArea: p => http.get('/publicize/broadcastArea/areaBroadcastList', p),//查询广播区域及设备
    getAudioList: p => http.get(`/publicize/audio/page`, p),//获取音频列表
    deleteAudio: p => http.deleteJson(`/publicize/audio/remove`, p),//删除音频文件
    playAudio: p => http.postJson(`/publicize/broadcast/startBroadcastTask`, p),//多音频播放
    setTaskEnable: p => http.put(`/publicize/broadcast/setTaskEnable`, p),//暂停/开启任务
    removeTask: p => http.deleteJson(`/publicize/broadcast/removeTask`, p),//暂停/开启任务
    getVoiceTaskList: p => http.get(`/publicize/broadcast/getTaskPage`, p),//查询当前广播任务
    addVoiceTask: p => http.postJson(`/publicize/broadcast/startBroadcastTask`, p),//添加对讲任务\播放任务
    endVoiceTask: p => http.postJson(`/publicize/broadcast/broadcastTaskStop`, p),//停止当前广播任务
    quietVoiceTask: p => http.postJson(`/publicize/broadcast/broadcastMute`, p),//广播任务静言
    addVoiceDevice: p => http.postJson(`/publicize/broadcast/broadcastAdd`, p),//广播任务额外添加设备
    pauseVoiceTask: p => http.postJson(`/publicize/broadcast/broadcastTaskPause`, p),//暂停or继续广播任务
    outVoiceDevice: p => http.postJson(`/publicize/broadcast/broadcastKick`, p),//踢除设备
    broadcastMute: p => http.postJson(`/publicize/broadcast/broadcastMute`, p),//静音
    broadcastMuteRelease: p => http.postJson(`/publicize/broadcast/broadcastMuteRelease`, p),//取消静音

}

//看板内容
const kanbanMngApi = {
    // 内容列表   
    queryContentSetList: p => http.get('/publicize/contentSet/search', p),
    updatePublicizeRes: p => http.put('/publicize/contentSet/updatePublicizeRes', p),
    getPublicizeDeviceList2: p => http.get('/publicize/contentSet/getPublicizeDeviceList', p),
    getPublicizeDeviceList: p => http.get('/device/device/getLedDevice', p),
    publicizeShowPage: p => http.get('/publicize/contentSet/publicizeShowPage', p),
    querySettingDetails: p => http.get(`/publicize/contentSet/${p}`),
    addPublicizeRes: p => http.postJson(`/publicize/contentSet/addPublicizeRes`, p),  //新增和编辑
    addAndSavePublicize: p => http.postJson(`/publicize/contentSet/addAndSavePublicize`, p),  //新增并修改投放计划
    savePublicizePlan: p => http.postJson(`/publicize/contentSet/savePublicizePlan`, p),  //修改投放计划
    deletePublicize: p => http.delete(`/publicize/contentSet/delResource`, p),  //删除投放资源

    removePublicizePlan: p => http.deleteJson(`/publicize/contentSet/removePublicizePlan`, p),  //删除投放资源
    getByAreaId: p => http.get('/publicize/broadcast/getByAreaId', p),//通过区域id获取广播设备

}

//基础配置
const baseConfig = {
    getBaseConfig: p => http.get('/publicize/broadcastArea/page', p),//获取广播区域
    addBaseConfig: p => http.postJson('/publicize/broadcastArea/save', p),//添加广播区域
    deleteBaseConfig: p => http.deleteJson('/publicize/broadcastArea/remove', p),//删除广播区域
}

export default Object.assign({}, broadcastMngApi, kanbanMngApi, baseConfig)