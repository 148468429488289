/*
    content:项目信息管理系统接口列表(接口统一加上前缀/project)
    time:2020-10-12
    
*/ 
import http from '../http'

//项目管理
const projectMngApi = {
    queryProject: p => http.get('/project/project/getProject', p ), //项目-详情
    updateProject: p => http.put('/project/project', p ), //项目-编辑
    insertProject: p => http.postJson('/project/project', p ), //项目-新增
    queryThirdParty: p => http.get('/project/thirdParty/getThirdParty', p ), //项目-住建局

    //参建单位
    queryCompanyList: p => http.get('/project/company/getCompanyList', p), //参建单位-列表
    queryCompanyDetails: p => http.get('/project/company/details', p), //参建单位-详情
    insertProjectCompany: p => http.postJson('/project/company', p), //参建单位-添加
    updateProjectCompany: p => http.put('/project/company', p), //参建单位-编辑
    deleteProjectCompany: p => http.delete('/project/company', p), //参建单位-删除
    projectCompanyExit: p => http.postJson('/project/company/companyExit', p), //参建单位-退场
    

    //班组管理
    queryTeamList: p => http.get('/project/team/getTeamList', p), //班组-列表
    queryTeamDetails: p => http.get('/project/team/details', p), //班组-详情
    insertProjectTeam: p => http.postJson('/project/team', p), //班组-添加
    updateProjectTeam: p => http.put('/project/team', p), //班组-编辑
    deleteProjectTeam: p => http.delete('/project/team', p), //班组-删除
    projectTeamExit: p => http.postJson('/project/team/teamExit', p), //班组-退场

    //账号管理
    queryUserList: p => http.get('/project/user/getUserList', p), //账号-列表
    queryUserDetails: p => http.get('/project/user/details', p), //账号-列表
    insertUser: p => http.postJson('/project/user', p), //账号-添加
    updateUser: p => http.put('/project/user/updateUser', p), //账号-编辑
    deleteUser: p => http.delete('/project/user', p), //账号-删除
    changeUserStatus: p => http.put('/project/user', p), //账号-启用/禁用

     //角色管理
     queryRoleList: p => http.get('/project/role/page', p), //角色-列表
     queryRoleDetails: p => http.get('/project/role/details', p), //角色-详情
     insertRole: p => http.postJson('/project/role', p), //角色-添加
     updateRole: p => http.put('/project/role/updateRole', p), //角色-编辑
     deleteRole: p => http.delete('/project/role', p), //角色-编辑
     changeRoleStatus: p => http.put('/project/role', p), //角色-禁用/启用

    //工种管理、职务管理（	type  ：1=工种 2=职务）
    queryJobsList: p => http.get('/project/jobs/getJobsList', p), //工种/职务-列表
    queryJobsDetails: p => http.get('/project/jobs/details', p), //工种/职务-详情
    insertJobs: p => http.postJson('/project/jobs', p), //工种/职务-添加
    updateJobs: p => http.put('/project/jobs', p), //工种/职务-编辑
    deleteJobs: p => http.delete('/project/jobs', p), //工种/职务-删除
    changeJobsStatus: p => http.put('/project/jobs', p), //工种/职务-启用禁用

    //单位类型
    queryCompanyTypeList: p => http.get('/project/companyType/getCompanyTypeList', p), //单位类型-列表
    changeProjectCompanyType: p => http.put('/project/companyType', p), //单位类型-禁用/启用
    insertProjectCompanyType: p => http.postJson('/project/companyType', p), //单位类型-添加
    updateProjectCompanyType: p => http.put('/project/companyType', p), //单位类型-编辑
    deleteProjectCompanyType: p => http.delete('/project/companyType', p), //单位类型-删除
    
}


//进度管理
const progressMngApi = {
    //里程牌管理
    queryMilestOneList: p => http.get('/project/milestone/getMilestOneList', p), //里程牌-列表
    deleteMilestOne: p => http.delete(`/project/milestone/${p}`), //里程牌-删除
    updateMilestOne: p => http.put(`/project/milestone`,p), //里程牌-编辑
    insertMilestOne: p => http.postJson(`/project/milestone`,p), //里程牌-添加


    //里程牌配置
    queryMilestoneConfigList: p => http.get('/project/milestoneConfig/getMilestoneConfigList', p), //里程牌-列表
    deleteMilestoneConfig: p => http.delete(`/project/milestoneConfig/${p}`), //里程牌-删除
    updateMilestoneConfig: p => http.put(`/project/milestoneConfig`,p), //里程牌-编辑
    insertMilestoneConfig: p => http.postJson(`/project/milestoneConfig`,p), //里程牌-添加

    queryMilestoneSonList: p => http.get('/project/milestoneSon/getMilestoneSonList', p), //子程碑-列表
    deleteMilestoneSon: p => http.delete(`/project/milestoneSon/${p}`), //子程碑-删除
    updateMilestoneSon: p => http.put(`/project/milestoneSon`,p), //子程碑-编辑
    insertMilestoneSon: p => http.postJson(`/project/milestoneSon`,p), //子程碑-添加


    //进度管理
    

    //验收
    queryAcceptanceList: p => http.get('/project/milestoneSon/getAcceptanceList', p), //验收-列表
    projectAcceptance: p => http.put('/project/milestoneSon', p), //验收-列表
}


export default Object.assign({},projectMngApi,progressMngApi)



